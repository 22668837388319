import { NotificationsResType } from 'services/events/evetns.api.type';
import { RootState } from 'store';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType, EventTicketGroupResType } from 'types/events/eventTypes';

export const eventsSliceSelector = (state: RootState) => state.eventsSlice;

export const createEventFormActiveStepSelector = (state: RootState): CREATE_EVENT_FORM_STEPS_ENUM =>
  eventsSliceSelector(state).activeStep;

export const createEventFormActiveEventSelector = (
  state: RootState,
): EventCreatedType | undefined => eventsSliceSelector(state)?.activeEvent;

export const createEventFormEditingEventIdSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.editingEventId;

export const EditingPromoterIdSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.editingPromoterId;

export const SubStepSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.subStep;

export const ShowSubStepSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.showSubStep;

export const isPublishingEventSelector = (state: RootState): boolean | undefined =>
  eventsSliceSelector(state)?.isPublishingEvent;

export const isEditingEventSelector = (state: RootState): boolean | undefined =>
  eventsSliceSelector(state)?.isEditing;

export const eventNotificaionsSelector = (state: RootState): NotificationsResType[] | undefined =>
  eventsSliceSelector(state)?.notifications;

export const activeDateIdSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.activeDateId;

export const activeEventNameSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.activeEventName;

export const ticketTypeGroupsSelector = (state: RootState): EventTicketGroupResType[] | undefined =>
  eventsSliceSelector(state)?.ticketGroups;
