import { FC, useCallback, useEffect, useRef, useState } from 'react';

import countries from 'i18n-iso-countries';
import PhoneInput from 'react-phone-input-2';
import mt from 'react-phone-input-2';
import { useParams } from 'react-router-dom';
import WorldFlag from 'react-world-flags';
import { extractFlyerName } from 'tools/extractFlyerName';
import { useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Checkbox, Form, Icon, Input, Label, Select, Typography, message } from 'ui';

import {
  SubStepSelector,
  createEventFormActiveEventSelector,
} from 'selectors/eventsSlice.selector';
import { useAddedTaxesAndContactMutation } from 'services/events/eventsApiService';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveEvent, setIsEditingEvent, setShowSubStep } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { REFUND_POLICY_TYPE } from 'types/events/eventTypes';

import EventCreateFormBasicInfoPromoter from '../event-create-form-basic-info/event-create-form-basic-info-promoter';
import s from './EventCreateFormTaxes.module.scss';
import { EventCreateTaxesFormType, TAXES_FORM_ENUM } from './eventCreateFormTaxes.utils';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const EventCreateFormTaxes: FC = () => {
  const [form] = Form.useForm();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [addedTaxesAndContacts, { isLoading }] = useAddedTaxesAndContactMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getPromoterFullInfo, { data: promoter }] = useLazyGetPromoterInfoQuery();
  const { eventId } = useParams();
  const currentSubStep = useAppSelector(SubStepSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [editingList, setEditingList] = useState<EventCreateTaxesFormType>();
  const dispatch = useAppDispatch();
  const [isDisableVat, setIsDisableVat] = useState(false);
  const setQuery = useSetQuery();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [userInitCountry, setUserInitCountry] = useState<string>('');
  const [country, setCountry] = useState<
    { name: string; flag: string; code?: string; latlng: string[] }[]
  >([]);

  const contentRef1 = useRef<HTMLDivElement>(null);
  const contentRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Clear any existing timeout to prevent overlapping triggers
          if (timeoutId) clearTimeout(timeoutId);

          // Set a timeout to delay dispatch by 0.5 seconds
          timeoutId = setTimeout(() => {
            dispatch(setShowSubStep(entry.target.id));
          }, 500); // 0.5 seconds delay
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% of the element is visible
    });

    const elements = [contentRef1.current, contentRef2.current];

    elements.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      // Clean up observer and timeout when component unmounts
      if (timeoutId) clearTimeout(timeoutId);
      elements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentSubStep === 'Taxes') {
      contentRef1?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
    if (currentSubStep === 'CONTACTS') {
      contentRef2?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
  }, [currentSubStep, contentRef1, contentRef2]);

  // const selectedCountryCode = country?.find(
  //   (country) => country.name === selectedCountry?.value,
  // )?.code;

  const handleValuesChange = (_, values) => {
    setIsEditing(true);
    dispatch(setIsEditingEvent(true));
    setEditingList(values);
    setIsDirty(true);
  };

  const handleChangeDisableVat = (value) => {
    setIsDisableVat(value);
    setIsEditing(true);
    dispatch(setIsEditingEvent(true));
  };

  const handleSubmit = useCallback(
    async (data: EventCreateTaxesFormType) => {
      const res = await addedTaxesAndContacts({
        eventId: activeEvent?.eventId || '',
        taxDetails: !isDisableVat
          ? {
              permitNumber: data.permitNumber as string,
              vatRate: data.vatRate as number,
              refundPolicy: REFUND_POLICY_TYPE.NO_REFUNDS,
            }
          : {
              permitNumber: null,
              vatRate: 0,
              refundPolicy: REFUND_POLICY_TYPE.NO_REFUNDS,
            },
        contactDetails: {
          email: data[TAXES_FORM_ENUM.EMAIL],
          mobileNumber: data[TAXES_FORM_ENUM.MOBILE_NUMBER],
          address: data[TAXES_FORM_ENUM.ADDRESS],
          town: data[TAXES_FORM_ENUM.TOWN],
          postcode: data[TAXES_FORM_ENUM.POST_CODE],
          country: data[TAXES_FORM_ENUM.COUNTRY].value,
        },
      });

      if ('data' in res) {
        setIsEditing(false);
        dispatch(setIsEditingEvent(false));
        dispatch(setActiveEvent(res?.data));
        message.success('Tax data successfully saved!');
        if (!eventId) {
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PROMOTE);
        }
      }
    },
    [activeEvent?.eventId, eventId, addedTaxesAndContacts, dispatch, isDisableVat, setQuery],
  );
  useEffect(() => {
    getCurrentUser().then((data) => {
      if (data?.data?.promoters?.length) {
        getPromoterFullInfo(data?.data?.promoters[0]?.promoterId || '');
      }
    });
  }, [getCurrentUser, getPromoterFullInfo]);

  const getUserCountry = useCallback(async (ip) => {
    const res = await fetch(`https://api.iplocation.net/?cmd=ip-country&ip=${ip}`);

    const data: {
      country_code2: string;
      country_name: string;
      ip: string;
      ip_number: string;
      ip_version: number;
      isp: string;
      response_code: string;
      response_message: string;
    } = await res.json();

    if (data?.country_name) {
      setUserInitCountry(data.country_name);
    }
  }, []);

  const getCountries = useCallback(async () => {
    const responseCountries = await fetch('https://restcountries.com/v3.1/all');
    const data = await responseCountries.json();
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        if (ipAddress) {
          getUserCountry(ipAddress);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setCountry(
      data
        ?.map((item) => ({
          name: item.name.common,
          flag: item.flag,
          code: item?.altSpellings[0],
          latlng: item?.capitalInfo?.latlng,
        }))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    );
  }, [getUserCountry]);

  useEffect(() => {
    const location = activeEvent?.contactDetails;

    if (location?.address) {
      form?.setFieldValue(TAXES_FORM_ENUM.ADDRESS, location?.address);
    }

    if (location?.email) {
      form?.setFieldValue(TAXES_FORM_ENUM.EMAIL, location?.email);
    }

    if (location?.mobileNumber) {
      form?.setFieldValue(TAXES_FORM_ENUM.MOBILE_NUMBER, location?.mobileNumber);
    }

    if (location?.postCode) {
      form?.setFieldValue(TAXES_FORM_ENUM.POST_CODE, location?.postCode);
    }

    if (location?.town) {
      form?.setFieldValue(TAXES_FORM_ENUM.TOWN, location?.town);
    }

    if (location?.country) {
      form?.setFieldValue(TAXES_FORM_ENUM.COUNTRY, { value: location?.country });
    } else {
      form?.setFieldValue(TAXES_FORM_ENUM.COUNTRY, { value: userInitCountry });
    }
  }, [form, userInitCountry, activeEvent]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <div className={s.wrapper} style={{ marginBottom: isEditing ? '100px' : '0' }}>
      <Form form={form} onValuesChange={handleValuesChange} onFinish={handleSubmit}>
        <div className={s.detailHeader} ref={contentRef1} id="Taxes">
          <Typography type="h2" className={s.title}>
            {t('common_taxes')}
          </Typography>

          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {extractFlyerName(activeEvent?.media.flyer! || '') ? (
              <img src={activeEvent?.media.flyer!} alt="flyer" className={s.img} />
            ) : (
              <div className={s.emptyFlyer}>
                <Icon name="littleLogo" />
              </div>
            )}
            <Typography type="h4">{activeEvent?.basicInfo.eventName}</Typography>
          </div>
        </div>
        <div className={s.form}>
          <div className={s.info}>
            <Typography type="main">{t('event_create_taxes_text')}</Typography>

            <div className={s.formContent}>
              <div>
                <Checkbox value={isDisableVat} onChange={handleChangeDisableVat}>
                  VAT is not applicable
                </Checkbox>
              </div>
              {!isDisableVat && (
                <>
                  {activeEvent?.basicInfo?.location?.country && (
                    <div>
                      <div className={s.countryWrapper}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <WorldFlag
                            code={countries.getAlpha2Code(
                              activeEvent?.basicInfo?.location?.country,
                              'en',
                            )}
                            style={{ width: 32, height: 20 }}
                          />
                          <Typography type="main">
                            {activeEvent?.basicInfo?.location?.country}
                          </Typography>
                        </div>
                        <Icon name="arrowDropdown" />
                      </div>
                      <Typography type="main">
                        Malta requires all events to possess a VAT number as well as a Permit Number
                        aka Exemption number eg: 24/345. To find out more about how to get this
                        please read our help files.
                      </Typography>
                    </div>
                  )}
                  <div className={s.row}>
                    <div className={s.item}>
                      <Label>{t('common_vat_number')}</Label>
                      <Input name="vatNumber" value={promoter?.billingVATNumber} disabled />
                    </div>
                    <div className={s.item}>
                      <Label required>{t('common_vat_permit')}</Label>
                      <Form.Item
                        name={TAXES_FORM_ENUM.PERMIT_NUMBER}
                        initialValue={activeEvent?.taxDetails?.permitNumber}>
                        <Input name="permit" placeholder="123/23" disabled={isDisableVat} />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <Label required>{t('common_vat_rate')}</Label>
                    <Form.Item
                      name={TAXES_FORM_ENUM.VAT_RATE}
                      initialValue={activeEvent?.taxDetails?.vATRate.toString()}>
                      <Input name="vatRate" placeholder="0" disabled={isDisableVat} />
                    </Form.Item>
                  </div>
                </>
              )}
              <div style={{ marginTop: 24 }} ref={contentRef2} id="CONTACTS">
                <Typography type="h3" weight={700}>
                  Promoter Contact Information
                </Typography>
                <div style={{ marginTop: 8, marginBottom: 16 }}>
                  <Typography type="main">
                    Please read this carefully and submit correct information. The information
                    underneath will be shown on each ticket which will be sold. You need to make
                    sure your Address, Email, Phone Number are correctly inputted. All the fields
                    are required and cannot be left blank.
                  </Typography>
                </div>

                <div style={{ marginBottom: 16 }}>
                  <Label required>{t('common_email')}</Label>
                  <Form.Item name={TAXES_FORM_ENUM.EMAIL}>
                    <Input name={TAXES_FORM_ENUM.EMAIL} />
                  </Form.Item>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Label required>{t('common_phone_number')}</Label>
                  <Form.Item name={TAXES_FORM_ENUM.MOBILE_NUMBER}>
                    <PhoneInput
                      containerClass={s.phoneContainer}
                      inputClass={s.phone}
                      buttonClass={s.phoneButton}
                      regions="europe"
                      preferredCountries={['mt']}
                      country="mt"
                      localization={mt}
                    />
                  </Form.Item>
                </div>
                <div style={{ marginBottom: 16 }}>
                  <Label>Country</Label>
                  <Form.Item name={TAXES_FORM_ENUM.COUNTRY} fullWidth>
                    <Select
                      options={country?.map((item) => ({
                        value: item.name,
                        label: `${item.flag} ${item.name}`,
                      }))}
                      showSearch
                      filterOption={(input, option) =>
                        String(option?.label ?? '')
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      fullWidth
                    />
                  </Form.Item>
                </div>

                <div style={{ marginBottom: 16 }}>
                  <Label>Address</Label>
                  <div className={s.location}>
                    <Form.Item name={TAXES_FORM_ENUM.ADDRESS} fullWidth>
                      <Input name={TAXES_FORM_ENUM.ADDRESS} placeholder="Enter Address" />
                    </Form.Item>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                  <div style={{ width: '100%' }}>
                    <Label>Town</Label>
                    <div className={s.location}>
                      <Form.Item name={TAXES_FORM_ENUM.TOWN} fullWidth>
                        <Input name={TAXES_FORM_ENUM.TOWN} placeholder="Enter Town" />
                      </Form.Item>
                    </div>
                  </div>
                  <div style={{ width: '100%' }}>
                    <Label>Postcode</Label>
                    <div className={s.location}>
                      <Form.Item name={TAXES_FORM_ENUM.POST_CODE} fullWidth>
                        <Input name={TAXES_FORM_ENUM.POST_CODE} placeholder="Enter Postcode" />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={s.taxPromoter}>
            <EventCreateFormBasicInfoPromoter />
          </div>
        </div>
        <FormSubmit
          createEvent={!eventId}
          isDirty={isDirty}
          isEditing={isEditing}
          editingList={editingList}
          type={isDisableVat ? 'empty' : 'taxesDetails'}
          fetching={isLoading}
        />
      </Form>
    </div>
  );
};

export default EventCreateFormTaxes;
