import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';

import { CalendarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import 'antd/lib/input-number/style/index';
import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { Checkbox, DatePicker, Form, Icon, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { createEventFormBasicInfoContext } from '../../basicInfo.utils';
import { TIME_FORMAT } from '../eventCreateFormBasicInfoDates.utils';
import s from './SingleDate.module.scss';

interface SingleDateProps {
  validateStartDate?: boolean;
  setEditIndex: Dispatch<SetStateAction<number>>;
}

const SingleDate: FC<SingleDateProps> = (props) => {
  const { validateStartDate, setEditIndex } = props;
  const { form } = useContext(createEventFormBasicInfoContext);
  const startDate = Form.useWatch(['showDates', 'startDate'], form);
  const startTime = Form.useWatch(['showDates', 'startTime'], form);
  const endDate = Form.useWatch(['showDates', 'endDate'], form);
  const endTime = Form.useWatch(['showDates', 'endTime'], form);
  const isSpecifyEndTime = Form.useWatch(['showDates', 'isSpecifyEndTime'], form);
  const [isEndTime, setIsEndTime] = useState(false);
  const [disabledTimeInfo, setDisabledTimeInfo] = useState<{
    disabledHours: number[];
    disabledMinutes: number[];
    disabledSeconds: number[];
  }>({
    disabledHours: [],
    disabledMinutes: [],
    disabledSeconds: [],
  });
  const [disabledEndTimeInfo, setDisabledEndTimeInfo] = useState<{
    disabledHours: number[];
    disabledMinutes: number[];
    disabledSeconds: number[];
  }>({
    disabledHours: [],
    disabledMinutes: [],
    disabledSeconds: [],
  });
  // const [isShowDoorOpened, setIsShowDoorOpened] = useState<boolean>(false);
  const startDateTime = `${dayjs(startDate).format('DD-MMM-YYYY')} ${dayjs(startTime).format(
    TIME_FORMAT,
  )}`;
  const endDateTime = `${dayjs(endDate).format('DD-MMM-YYYY')} ${dayjs(endTime).format(
    TIME_FORMAT,
  )}`;
  const disabledDate = (current) => {
    return {
      startDate:
        current && endDate
          ? current < dayjs().startOf('day') || current > dayjs(endDate).endOf('day')
          : current < dayjs().startOf('day'),
      endDate: current && current < dayjs(startDate).startOf('day'),
    };
  };

  const handleClick = () => {
    form?.setFieldValue(['showDates', 'startDate'], '');
    form?.setFieldValue(['showDates', 'startTime'], '');
    form?.setFieldValue(['showDates', 'doorsOpen'], '');
    form?.setFieldValue(['showDates', 'endDate'], '');
    form?.setFieldValue(['showDates', 'endTime'], '');
    setEditIndex(-1);
  };

  const handleCheckBox = () => {
    setIsEndTime(!isEndTime);
    form?.setFieldValue(['showDates', 'isSpecifyEndTime'], !isEndTime);
    if (isEndTime === false) {
      form?.setFieldValue(['showDates', 'endDate'], null);
      form?.setFieldValue(['showDates', 'endTime'], null);
    }
  };

  useEffect(() => {
    const duration = dayjs(endDateTime).diff(dayjs(startDateTime), 'millisecond');
    const eventDuration = {
      days: Math.floor(duration / (1000 * 3600 * 24)),
      hours: Math.floor((duration % (1000 * 3600 * 24)) / (1000 * 3600)),
      mins: Math.floor((duration % (1000 * 3600)) / (1000 * 60)),
    };

    if (endDate && endTime) {
      form?.setFieldValue(['showDates', 'duration', 'days'], eventDuration.days || 0);
      form?.setFieldValue(['showDates', 'duration', 'hours'], eventDuration.hours || 0);
      form?.setFieldValue(['showDates', 'duration', 'minutes'], eventDuration.mins || 0);
    }
  }, [endDate, endDateTime, endTime, form, startDateTime]);

  useEffect(() => {
    if (startTime) {
      form?.setFieldValue(['showDates', 'doorsOpen'], startTime.subtract(1, 'hours'));
    }
  }, [form, startTime]);

  useEffect(() => {
    if (startTime) {
      const hour = startTime.hour();
      // const minute = startTime.minute();

      const disabledHours: number[] = [];
      const disabledMinutes: number[] = [];
      const disabledSeconds: number[] = [];

      for (let h = hour + 1; h < 24; h++) {
        disabledHours.push(h);
      }
      // if (hour === startTime.hour()) {
      //   for (let m = minute + 1; m < 60; m++) {
      //     disabledMinutes.push(m);
      //   }
      // }

      setDisabledTimeInfo({ disabledHours, disabledMinutes, disabledSeconds });
    }
  }, [startTime]);

  useEffect(() => {
    if (startDate && endDate && startTime) {
      const startHour = startTime.hour();

      const disabledHours: number[] = [];
      const disabledMinutes: number[] = [];
      const disabledSeconds: number[] = [];

      // Check if the startDate and endDate are the same day
      const isSameDay = startDate.isSame(endDate, 'day');

      if (isSameDay) {
        // Disable hours before the start time hour
        for (let h = 0; h < startHour; h++) {
          disabledHours.push(h);
        }

        // If the selected hour is the same as startTime, disable minutes before the start minute
      }

      setDisabledEndTimeInfo({ disabledHours, disabledMinutes, disabledSeconds });
    }
  }, [startDate, endDate, startTime, endTime]);

  return (
    <div className={s.wrapper}>
      <div style={{ marginTop: 8 }}>
        <Typography type="h3">Add Date</Typography>
      </div>
      <div className={s.item}>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_start_date')}
          </Typography>
          <Form.Item name={['showDates', 'showId']} hidden>
            <div></div>
          </Form.Item>
          <Form.Item
            name={['showDates', 'startDate']}
            valuePropName="value"
            rules={!validateStartDate ? [formRules.required] : []}>
            <DatePicker
              fullWidth
              format="dddd DD MMMM YYYY"
              disabledDate={(current) => disabledDate(current).startDate}
              nextIcon={<Icon name="doubleRightArrow" size={18} />}
              prevIcon={<Icon name="doubleLeftArrow" size={18} />}
            />
          </Form.Item>
        </div>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_start_time')}
          </Typography>
          <Form.Item
            name={['showDates', 'startTime']}
            rules={!validateStartDate ? [formRules.required] : []}>
            <DatePicker.TimePicker placeholder="Select time" fullWidth format={TIME_FORMAT} />
          </Form.Item>
        </div>
      </div>
      <div className={s.item}>
        {/* <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_duration')}
          </Typography>
          <div className={s.duration}>
            <Form.Item name={['showDates', 'duration', 'days']}>
              <InputNumber min={0} max={24} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>days</span>
            <Form.Item name={['showDates', 'duration', 'hours']}>
              <InputNumber min={0} max={24} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>hrs</span>
            <Form.Item name={['showDates', 'duration', 'minutes']}>
              <InputNumber min={0} max={60} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>mins</span>
          </div>
        </div> */}

        {/* {!isShowDoorOpened && (
              <button className={s.openingButton} onClick={() => setIsShowDoorOpened(true)}>
                Add door opening time +
              </button>
            )} */}

        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_doors_open')}
          </Typography>
          <Form.Item name={['showDates', 'doorsOpen']}>
            <DatePicker.TimePicker
              fullWidth
              format={TIME_FORMAT}
              disabledTime={() => ({
                disabledHours: () => disabledTimeInfo.disabledHours,
              })}
            />
          </Form.Item>
        </div>
        <div className={s.field}>
          <Form.Item
            name={['showDates', 'isSpecifyEndTime']}
            initialValue={false}
            style={{ display: 'none' }}>
            <Checkbox checked={isEndTime}>
              <Typography type="main" weight={600}>
                Specify End Time
              </Typography>
            </Checkbox>
          </Form.Item>
          <Button
            style={{
              marginTop: 24,
              backgroundColor: '#E7E9F1',
              fontWeight: 700,
              fontSize: 13,
              borderRadius: 4,
            }}
            color="ghost"
            size="large"
            onClick={handleCheckBox}>
            <CalendarOutlined />
            {!isEndTime ? 'SPECIFY END TIME' : 'UNSPECIFY END TIME'}
          </Button>
        </div>
      </div>
      {isSpecifyEndTime && (
        <div className={s.item}>
          <div className={s.field}>
            <Typography type="small" className={cn(s.label)}>
              {t('event_create_form_end_date')}
            </Typography>
            <Form.Item name={['showDates', 'endDate']} initialValue={null}>
              <DatePicker
                fullWidth
                format="dddd DD MMMM YYYY"
                defaultPickerValue={startDate}
                nextIcon={<Icon name="doubleRightArrow" size={18} />}
                prevIcon={<Icon name="doubleLeftArrow" size={18} />}
                disabledDate={(current) => disabledDate(current).endDate}
              />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Typography type="small" className={cn(s.label)}>
              {t('event_create_form_end_time')}
            </Typography>
            <Form.Item name={['showDates', 'endTime']} initialValue={null}>
              <DatePicker.TimePicker
                fullWidth
                format={TIME_FORMAT}
                disabledTime={() => ({
                  disabledHours: () => disabledEndTimeInfo.disabledHours,
                  disabledMinutes: () => disabledEndTimeInfo.disabledMinutes,
                })}
              />
            </Form.Item>
          </div>
        </div>
      )}

      <div className={s.item}>
        <div className={s.field}>
          <Form.Item name={['showDates', 'isShowHidden']} initialValue={false}>
            <Checkbox>
              <Typography type="main" weight={600}>
                Hide the date from the public?
              </Typography>
            </Checkbox>
          </Form.Item>
        </div>
        {(startDate || startTime) && (
          <div className={s.field}>
            <Button color="grey" onClick={handleClick}>
              Reset Date
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleDate;
