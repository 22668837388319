import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { extractFlyerName } from 'tools/extractFlyerName';
import { useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Input, Label, Switch, Typography, message } from 'ui';

import {
  SubStepSelector,
  createEventFormActiveEventSelector,
  isEditingEventSelector,
  ticketTypeGroupsSelector,
} from 'selectors/eventsSlice.selector';
import {
  useAddedTicketTypeMutation,
  useEditEventBaseInfoMutation,
  useLazyGetSeatingPlansQuery,
  useLazyGetTicketGroupsQuery,
} from 'services/events/eventsApiService';
import { SeatingPlansResponseType } from 'services/events/evetns.api.type';
import { useGetAvailableCurrenciesQuery } from 'services/system/systemApiService';
import {
  setActiveEvent,
  setIsEditingEvent,
  setShowSubStep,
  setTicketGroups,
} from 'slices/eventsSlice';
import { setActiveStep } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import {
  EventTicketGroupResType,
  EventTicketType,
  TICKET_SALES_STATUS,
} from 'types/events/eventTypes';

import s from './EventCreateFormTickets.module.scss';
import CreateTicketModal from './create-ticket-modal';
import CreateTicketGroupModal from './create-ticketgroup-modal';
import DebounceSelect from './debounce-select';
import TicketDateTab from './ticket-date-tab';
import TicketGroupsList from './ticket-groups-list';
import TicketsList from './tickets-list';

const convertedOptions = (seatingPlan: SeatingPlansResponseType) => {
  return seatingPlan.map((plan, index) => ({
    key: index,
    label: plan.name,
    value: plan.id,
  }));
};

export interface DateTicketStatus {
  id: string;
  name: string;
  status: TICKET_SALES_STATUS;
}

const EventCreateFormTickets: FC = () => {
  const [openedCreateModal, setOpenedCreateModal] = useState<boolean>(false);
  const [openedCreateTicketGroupModal, setOpenedCreateTicketGroupModal] = useState<boolean>(false);
  const [editEvent, { isLoading: isEditingLoading }] = useEditEventBaseInfoMutation();
  const isEditing = useAppSelector(isEditingEventSelector);
  const currentSubStep = useAppSelector(SubStepSelector);
  const { eventId } = useParams();
  const [tickets, setTickets] = useState<EventTicketType[]>([]);
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const ticketGroups = useAppSelector(ticketTypeGroupsSelector);
  const [createTickets, { isLoading: updatingTickets }] = useAddedTicketTypeMutation();
  const [isBasicInfoModified, setIsBasicInfoModified] = useState<boolean>(false);
  const [hintIsChecked, setHintIsChecked] = useState<boolean>(false);

  const [isSettingError, setIsSettingError] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data: currencies } = useGetAvailableCurrenciesQuery();
  const [getTicketGroups] = useLazyGetTicketGroupsQuery();
  const activeCurrency = currencies?.find((item) => item.code === activeEvent?.basicInfo?.currency);
  const setQuery = useSetQuery();
  const [activeEditTicket, setActiveEditTicket] = useState<EventTicketType>();
  const [activeEditTicketGroup, setActiveEditTicketGroup] = useState<EventTicketGroupResType>();

  const [getSeatingPlan, { data: seatingPlan }] = useLazyGetSeatingPlansQuery();
  const [eventType, setEventType] = useState<boolean>(false);
  const [plan, setPlan] = useState<{ label: string; value: string } | null>(null);
  const isMaxAttendees = Form.useWatch('isMaxAttendees', form);
  const maxAttendeesAmount = Form.useWatch('maxAttendeesAmount', form);

  const [dateTicketStatus, setDateTicketStatus] = useState<DateTicketStatus[]>();
  const [currentDateId, setCurrentDateId] = useState<string>('');

  const contentRef1 = useRef<HTMLDivElement>(null);
  const contentRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Clear any previous timeout
          if (timeoutId) clearTimeout(timeoutId);

          // Set a delay before dispatching the action
          timeoutId = setTimeout(() => {
            dispatch(setShowSubStep(entry.target.id));
          }, 500); // 0.5s delay
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.4, // Intersection ratio
    });

    const elements = [contentRef1.current, contentRef2.current];

    elements.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      // Clean up observer and timeout on component unmount
      if (timeoutId) clearTimeout(timeoutId);
      elements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentSubStep === 'Tickets') {
      contentRef1?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
    if (currentSubStep === 'TICKET TYPES') {
      contentRef2?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
  }, [currentSubStep, contentRef1, contentRef2]);

  // const selectOptions = useMemo(() => {
  //   if (seatingPlan?.length) {
  //     return seatingPlan.map((plan, index) => (
  //       <Option key={index} value={plan.id}>
  //         {plan.name}
  //       </Option>
  //     ));
  //   } else {
  //     return [];
  //   }
  // }, [seatingPlan]);

  const fetchOptions = (search: string) => {
    return new Promise<{ label: string; value: string }[]>((resolve) => {
      if (seatingPlan) {
        const filteredOptions = convertedOptions(seatingPlan!).filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase()),
        );
        resolve(filteredOptions);
      }
    });
  };

  const handleFormValueChange = () => {
    setIsDirty(true);
    dispatch(setIsEditingEvent(true));
    if (!isMaxAttendees) {
      form.setFieldValue('maxAttendeesAmount', 0);
    }
  };

  const handleCheck = () => {
    const existingTickets = JSON.parse(localStorage.getItem('TicketGroupHintChecked')!) || [];

    if (!existingTickets.includes(activeEvent?.eventId)) {
      existingTickets.push(activeEvent?.eventId);
    }

    localStorage.setItem('TicketGroupHintChecked', JSON.stringify(existingTickets));
    setHintIsChecked(true);
  };

  const handleSubmitTickets = useCallback(async () => {
    const result = activeEvent?.basicInfo ? { ...activeEvent.basicInfo } : null;

    if (eventType && plan === null) {
      setIsSettingError(true);
      return;
    }

    if (result) {
      result.eventType = eventType ? 'seated' : 'standing';
      result.seatingPlanId = eventType ? plan?.value : null;
    }

    if (isBasicInfoModified || !eventId) {
      const res = await editEvent({
        basicInfo: result!,
        eventId: activeEvent?.eventId as string,
      });

      if ('data' in res) {
        dispatch(setIsEditingEvent(false));
        dispatch(setActiveEvent(res?.data));
        if (eventType) {
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST);
          dispatch(setActiveStep(CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST));
        } else {
          if (!eventId) {
            setQuery(CREATE_EVENT_FORM_STEPS_ENUM.TAXES);
          }
        }
      }
    }

    if (form.isFieldTouched('isMaxAttendees') || form.isFieldTouched('maxAttendeesAmount')) {
      const ticketsRes = await createTickets({
        ticketTypes: activeEvent?.ticketTypes!,
        ...(!!maxAttendeesAmount && {
          maxNumberOfAttendees: Number(maxAttendeesAmount),
        }),
        eventId: eventId || '',
      });
      if ('data' in ticketsRes) {
        message.success('Tickets data successfully saved!');
        dispatch(setIsEditingEvent(false));
        dispatch(setActiveEvent(ticketsRes?.data));
      }
    }
  }, [
    activeEvent?.eventId,
    eventId,
    activeEvent?.basicInfo,
    activeEvent?.ticketTypes,
    createTickets,
    maxAttendeesAmount,
    editEvent,
    dispatch,
    isBasicInfoModified,
    setQuery,
    eventType,
    form,
    plan,
  ]);

  const handleEventType = (e) => {
    setIsDirty(true);
    dispatch(setIsEditingEvent(true));
    setEventType(e.target.checked);
    setIsBasicInfoModified(true);
    if (e.target.checked) {
      form.setFieldValue('isMaxAttendees', false);
      setIsSettingError(false);
    }
  };

  const handleSetTickets = (
    newTickets: EventTicketType[] | ((prevState: EventTicketType[]) => EventTicketType[]),
  ) => {
    setTickets(newTickets);
    // setIsDirty(true);
    // dispatch(setIsEditingEvent(true));
  };

  useEffect(() => {
    getSeatingPlan();
  }, [getSeatingPlan]);

  const handleGetTicketGroups = useCallback(
    async (eventId: string) => {
      const res = await getTicketGroups(eventId);
      if ('data' in res) {
        const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
        dispatch(setTicketGroups(sortedResponse));
      }
    },
    [dispatch, getTicketGroups],
  );

  useEffect(() => {
    if (activeEvent?.eventId) {
      handleGetTicketGroups(activeEvent?.eventId);
    }
  }, [activeEvent?.eventId, handleGetTicketGroups]);

  useEffect(() => {
    if (activeEvent?.ticketTypes?.length) {
      setTickets(activeEvent.ticketTypes);
    }
    if (activeEvent?.basicInfo?.maximumNumberOfAttendees) {
      form.setFieldValue('isMaxAttendees', true);
      form.setFieldValue('maxAttendeesAmount', activeEvent?.basicInfo?.maximumNumberOfAttendees);
    }
  }, [activeEvent, form]);

  useEffect(() => {
    if (activeEditTicket?.name) {
      setOpenedCreateModal(true);
    }
  }, [activeEditTicket]);

  useEffect(() => {
    if (activeEditTicketGroup?.name) {
      setOpenedCreateTicketGroupModal(true);
    }
  }, [activeEditTicketGroup]);

  useEffect(() => {
    if (activeEvent?.basicInfo) {
      setEventType(activeEvent?.basicInfo?.eventType?.toLowerCase() === 'seated' ? true : false);

      if (activeEvent?.basicInfo?.eventType?.toLowerCase() === 'seated') {
        if (activeEvent?.basicInfo?.seatingPlanId) {
          const temp = seatingPlan?.find(
            (item) => item.id === activeEvent?.basicInfo?.seatingPlanId,
          );
          const tempObject = { label: temp?.name as string, value: temp?.id as string };
          setPlan(tempObject);
        }
      }
    }

    const storedValue = localStorage.getItem('TicketGroupHintChecked');
    let ticketGroup: string[] = [];

    try {
      ticketGroup = JSON.parse(storedValue as string) || [];
    } catch (error) {
      console.error('Error parsing localStorage value:', error);
      localStorage.setItem('TicketGroupHintChecked', JSON.stringify([]));
    }

    if (ticketGroup.includes(activeEvent?.eventId!)) {
      setHintIsChecked(true);
    }
  }, [activeEvent, seatingPlan]);

  return (
    <div
      className={s.contentWrapper}
      style={{ marginBottom: isEditing || isDirty ? '100px' : '0' }}>
      <div className={s.header} ref={contentRef1} id="Tickets">
        <Typography type="h2" className={s.title}>
          {t('common_tickets')}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {extractFlyerName(activeEvent?.media.flyer! || '') ? (
            <img src={activeEvent?.media.flyer!} alt="flyer" className={s.img} />
          ) : (
            <div className={s.emptyFlyer}>
              <Icon name="littleLogo" />
            </div>
          )}
          <Typography type="h4">{activeEvent?.basicInfo.eventName}</Typography>
        </div>
      </div>
      <div className={s.wrapper}>
        {activeEvent?.basicInfo.status !== 'published' &&
          activeEvent?.basicInfo.status !== 'approved' && (
            <div>
              <div>
                <Typography type="h4">Event type: standing or Seated</Typography>

                <div className={cn(s.switchContainer)}>
                  <label className={cn(s.switch)}>
                    <span
                      className={cn(s.switchLabel, {
                        [s.active]: !eventType,
                      })}>
                      <div>
                        <UserOutlined style={{ marginRight: 12 }} />
                        <span className={cn(s.span)}>STANDING EVENT</span>
                      </div>
                    </span>
                    <input type="checkbox" checked={eventType} onChange={handleEventType} />
                    <span className={cn(s.slider, { [s.checked]: eventType })}></span>
                    <span
                      className={cn(s.switchLabel, {
                        [s.active]: eventType,
                      })}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        <Icon name="seat" width={14} />
                        <span className={cn(s.span)}>SEATED EVENT</span>
                      </div>
                    </span>
                  </label>
                </div>

                {!eventType && (
                  <Typography className={cn(s.description)}>
                    Standing events are events where people are not tied to their seats
                  </Typography>
                )}
              </div>
              {eventType && (
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                  <Typography className={cn(s.description)}>
                    A seated event is one where the ticket buyers will choose a seat where they will
                    sit
                  </Typography>
                  <Typography className={cn(s.planLabel)} type="h3">
                    Select plan
                  </Typography>
                  <DebounceSelect
                    value={plan}
                    placeholder="Select users"
                    fetchOptions={fetchOptions}
                    onChange={(newValue) => {
                      if (Array.isArray(newValue)) {
                        setPlan(newValue.length > 0 ? newValue[0] : null);
                      } else {
                        setPlan(newValue);
                      }
                      dispatch(setIsEditingEvent(true));
                      setIsBasicInfoModified(true);
                    }}
                    style={{ width: '100%' }}
                  />
                  {isSettingError && (
                    <div style={{ color: 'red', fontSize: 14, marginLeft: 4 }}>
                      Select seating plan
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

        {eventType &&
          activeEvent?.basicInfo.status !== 'published' &&
          activeEvent?.basicInfo.status !== 'approved' && (
            <>
              <div className={s.hint}>
                <div>
                  <Icon name="warning" />
                </div>
                <Trans i18nKey="event_create_ticket_event_type_tip" components={{ b: <b /> }} />
              </div>

              <div className={s.seatingPlanImage}>
                <div className={s.firstImage}>
                  <img src="/assets/images/1.svg" alt="seatingplan" />
                </div>
                <div className={s.secondImage}>
                  <img src="/assets/images/2.svg" alt="seatingplan" />
                </div>
                <div className={s.thirdImage}>
                  <img src="/assets/images/3.svg" alt="seatingplan" />
                </div>
                <div className={s.fourthImage}>
                  <img src="/assets/images/4.svg" alt="seatingplan" />
                </div>
              </div>
            </>
          )}

        {/* {activeEvent?.basicInfo.seatingPlanId && (
          <div style={{ marginBottom: 16 }}>
            <Button className={s.seatingButton} onClick={goToSeatingPlan}>
              SELECT SEATING PLAN
            </Button>
          </div>
        )} */}

        {!eventType && (
          <Form className={s.maxAttendeesBlock} form={form} onValuesChange={handleFormValueChange}>
            <div className={s.block}>
              <Form.Item name="isMaxAttendees">
                <Switch size="small" />
              </Form.Item>
              <Typography type="main">
                {' '}
                {t('event_create_form_create_ticket_show_maximum_attendee')}
              </Typography>
            </div>
            {isMaxAttendees === true && (
              <div className={s.maxNumber}>
                <Label>Max number of people</Label>
                <Form.Item name="maxAttendeesAmount">
                  <Input.Number
                    name="maxAttendeesAmount"
                    min={0}
                    style={{ width: '100%' }}
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            )}
          </Form>
        )}
        <div ref={contentRef2} id="TICKET TYPES">
          <TicketDateTab
            setCurrentDateId={setCurrentDateId}
            setDateTicketStatus={setDateTicketStatus}
            eventId={activeEvent?.eventId}
            eventStatus={activeEvent?.basicInfo.status!}
          />
        </div>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          {!!tickets?.length && (
            <Button
              style={{ width: '100%', fontSize: 14, fontWeight: 700 }}
              onClick={() => setOpenedCreateModal(true)}>
              <Icon name="plus" />
              ADD TICKET TYPE
            </Button>
          )}
        </div>
        {tickets?.length > 1 && (
          <>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <Button
                style={{
                  width: '100%',
                  fontSize: 14,
                  fontWeight: 700,
                  backgroundColor: '#CDE3FF',
                  color: '#187DFD',
                }}
                onClick={() => setOpenedCreateTicketGroupModal(true)}>
                <Icon name="plus" />
                ADD TICKET TYPE GROUP
              </Button>
            </div>
            {!hintIsChecked && (
              <div className={s.ticketHint}>
                <div className={s.ticketGroupDIV}>
                  <div>
                    <Icon name="warning" />
                  </div>
                  <div>
                    <Typography type="main">
                      <b>TICKET GROUP</b> enables you to group ticket types under one specific
                      group. The group of tickets will be shown on the event page as shown in this
                      screenshot
                    </Typography>
                    <Button
                      onClick={handleCheck}
                      style={{ marginTop: 16, fontSize: 11, paddingLeft: 32, paddingRight: 32 }}>
                      GOT IT
                    </Button>
                  </div>
                </div>
                <img
                  className={s.ticketGroupImage}
                  src="/assets/images/TICKET_GROUP.svg"
                  alt="TICKET_GRUOUP"
                />
              </div>
            )}
          </>
        )}
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          {ticketGroups?.length! > 0 && (
            <Typography className={s.listStyle}>TICKET GROUPS</Typography>
          )}
          {ticketGroups && (
            <TicketGroupsList
              currentDateId={currentDateId}
              eventType={eventType}
              list={ticketGroups}
              currency={activeCurrency?.symbol || '€'}
              setActiveEditTicketGroup={setActiveEditTicketGroup}
              maxAttendeesAmount={maxAttendeesAmount}
              eventId={activeEvent?.eventId}
            />
          )}
        </div>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Typography className={s.listStyle}>TICKET TYPES</Typography>
          {tickets && currentDateId && (
            <TicketsList
              dateTicketStatus={dateTicketStatus}
              currentDateId={currentDateId}
              eventType={eventType}
              list={tickets}
              setList={setTickets}
              currency={activeCurrency?.symbol || '€'}
              setActiveEditTicket={setActiveEditTicket}
              maxAttendeesAmount={maxAttendeesAmount}
              eventId={activeEvent?.eventId}
            />
          )}
        </div>
        {!tickets?.length && (
          <button className={cn(s.create)} onClick={() => setOpenedCreateModal(true)}>
            + {t('event_create_form_create_ticket')}
          </button>
        )}
      </div>

      <FormSubmit
        createEvent={!eventId}
        isDirty={isDirty}
        isEditing={isEditing || isEditingLoading}
        onClick={handleSubmitTickets}
        fetching={isEditingLoading || updatingTickets}
      />

      <CreateTicketModal
        open={openedCreateModal}
        ticketGroups={ticketGroups!}
        onClose={setOpenedCreateModal}
        setTickets={handleSetTickets}
        currency={activeCurrency?.symbol || '€'}
        activeEditTicket={activeEditTicket}
        setActiveEditTicket={setActiveEditTicket}
        eventId={activeEvent?.eventId!}
        maxAttendeesAmount={maxAttendeesAmount}
      />

      <CreateTicketGroupModal
        open={openedCreateTicketGroupModal}
        onClose={setOpenedCreateTicketGroupModal}
        currency={activeCurrency?.symbol || '€'}
        activeEditTicketGroup={activeEditTicketGroup}
        setActiveEditTicketGroup={setActiveEditTicketGroup}
        eventId={activeEvent?.eventId!}
      />
    </div>
  );
};

export default EventCreateFormTickets;
