import { FC, SVGProps } from 'react';

import { ReactComponent as ArrowDownOutline } from './arrowDownOutline.svg';
import { ReactComponent as ArrowDropdown } from './arrowDropdown.svg';
import { ReactComponent as ArrowDropup } from './arrowDropup.svg';
import { ReactComponent as ArrowRight } from './arrowRight.svg';
import { ReactComponent as BankCard } from './bankCard.svg';
import { ReactComponent as Bell } from './bell.svg';
import { ReactComponent as BlockView } from './blockView.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as Briefcase } from './briefcase.svg';
import { ReactComponent as burgerMenu } from './burgerMenu.svg';
import { ReactComponent as CancelCircle } from './cancelCircle.svg';
import { ReactComponent as Charge } from './charge.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as CheckWide } from './checkWide.svg';
import { ReactComponent as Checked } from './checked.svg';
import { ReactComponent as Clock } from './clock.svg';
import { ReactComponent as CloseEye } from './close-eye.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Complete } from './complete.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Coupon } from './coupon.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Dots } from './dots.svg';
import { ReactComponent as DoubleLeftArrow } from './double-left-arrow.svg';
import { ReactComponent as DoubleRightArrow } from './double-right-arrow.svg';
import { ReactComponent as Download } from './download.svg';
import { ReactComponent as Drag } from './drag.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as EditEvent } from './editEvent.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as ExclamationTriangle } from './exclamationTriangle.svg';
import { ReactComponent as Eye } from './eye.svg';
import { ReactComponent as EyeOpen } from './eyeOpen.svg';
import { ReactComponent as Fiat } from './fiat.svg';
import { ReactComponent as File } from './file.svg';
import { ReactComponent as FilesFolder } from './filesFolder.svg';
import { ReactComponent as FinReport } from './financialReport.svg';
import { ReactComponent as Finish } from './finish.svg';
import { ReactComponent as GoTo } from './goTo.svg';
import { ReactComponent as Graph } from './graph.svg';
import { ReactComponent as Hint } from './hint.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Idea } from './idea.svg';
import { ReactComponent as IdeaColor } from './ideaHint.svg';
import { ReactComponent as Image } from './image.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Kiosk } from './kiosk.svg';
import { ReactComponent as Lightning } from './lightning.svg';
import { ReactComponent as Links } from './links.svg';
import { ReactComponent as List } from './list.svg';
import { ReactComponent as ListView } from './listView.svg';
import { ReactComponent as LittleLogo } from './littleLogo.svg';
import { ReactComponent as Loader } from './loader.svg';
import { ReactComponent as LoginEvent } from './login-event.svg';
import { ReactComponent as LoginManage } from './login-manage.svg';
import { ReactComponent as LoginMetric } from './login-metric.svg';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as MapMarker } from './mapMarker.svg';
import { ReactComponent as SquareMinus } from './minusSquare.svg';
import { ReactComponent as Offer } from './offer.svg';
import { ReactComponent as OpenEye } from './open-eye.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Permission } from './permission.svg';
import { ReactComponent as Play } from './play.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as SquarePlus } from './plusSquare.svg';
import { ReactComponent as Poster } from './poster.svg';
import { ReactComponent as Profile } from './profile.svg';
import { ReactComponent as Promote } from './promote.svg';
import { ReactComponent as Question } from './question.svg';
import { ReactComponent as Receipt } from './receipt.svg';
import { ReactComponent as RefreshArrows } from './refreshArrows.svg';
import { ReactComponent as Refund } from './refund.svg';
import { ReactComponent as Report } from './report.svg';
import { ReactComponent as Scan } from './scan.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Seat } from './seat.svg';
import { ReactComponent as Send } from './send.svg';
import { ReactComponent as Setting } from './setting.svg';
import { ReactComponent as ShLogo } from './shLogo.svg';
import { ReactComponent as ShowsManager } from './showsManager.svg';
import { ReactComponent as SimpleArrowRight } from './simpleArrowRight.svg';
import { ReactComponent as SmallLogo } from './small-logo.svg';
import { ReactComponent as Stats } from './stats.svg';
import { ReactComponent as Success } from './success.svg';
import { ReactComponent as SwapArrows } from './swapArrows.svg';
import { ReactComponent as TaxReport } from './tax-report.svg';
import { ReactComponent as Telegram } from './telegram.svg';
import { ReactComponent as Test } from './test.svg';
import { ReactComponent as Thumbnail } from './thumbnail.svg';
import { ReactComponent as Ticket } from './ticket.svg';
import { ReactComponent as Time } from './time.svg';
import { ReactComponent as Tip } from './tip.svg';
import { ReactComponent as TopUp } from './topUp.svg';
import { ReactComponent as TrashBox } from './trashBox.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as VerticalDots } from './verticalDots.svg';
import { ReactComponent as Video } from './video.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as Wifi } from './wifi.svg';
import { ReactComponent as X } from './x.svg';

export const CoreIcons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  setting: Setting,
  arrowDropdown: ArrowDropdown,
  arrowRight: ArrowRight,
  briefcase: Briefcase,
  check: Check,
  delete: Delete,
  eye: Eye,
  info: Info,
  success: Success,
  plus: Plus,
  profile: Profile,
  search: Search,
  telegram: Telegram,
  clock: Clock,
  test: Test,
  lightning: Lightning,
  exclamationTriangle: ExclamationTriangle,
  cancelCircle: CancelCircle,
  copy: Copy,
  edit: Edit,
  simpleArrowRight: SimpleArrowRight,
  topUp: TopUp,
  email: Email,
  time: Time,
  list: List,
  checkWide: CheckWide,
  bankCard: BankCard,
  verticalDots: VerticalDots,
  links: Links,
  trashBox: TrashBox,
  warning: Warning,
  fiat: Fiat,
  filesFolder: FilesFolder,
  loader: Loader,
  error: Error,
  file: File,
  bookmark: Bookmark,
  finish: Finish,
  swapArrows: SwapArrows,
  refreshArrows: RefreshArrows,
  bell: Bell,
  download: Download,
  arrowDownOutline: ArrowDownOutline,
  burgerMenu: burgerMenu,
  receipt: Receipt,
  logo: Logo,
  kiosk: Kiosk,
  thumbnail: Thumbnail,
  littleLogo: LittleLogo,
  idea: Idea,
  ideaColor: IdeaColor,
  eyeOpen: EyeOpen,
  tip: Tip,
  plusSquare: SquarePlus,
  minusSquare: SquareMinus,
  openEye: OpenEye,
  closeEye: CloseEye,
  drag: Drag,
  permission: Permission,
  mapMarker: MapMarker,
  listView: ListView,
  blockView: BlockView,
  graph: Graph,
  showsManager: ShowsManager,
  user: User,
  pause: Pause,
  play: Play,
  goTo: GoTo,
  loginEvent: LoginEvent,
  loginManage: LoginManage,
  loginMetric: LoginMetric,
  doubleRightArrow: DoubleRightArrow,
  doubleLeftArrow: DoubleLeftArrow,
  promote: Promote,
  charge: Charge,
  wifi: Wifi,
  smallLogo: SmallLogo,
  dots: Dots,
  close: Close,
  send: Send,
  stats: Stats,
  editEvent: EditEvent,
  report: Report,
  users: Users,
  taxReport: TaxReport,
  coupon: Coupon,
  question: Question,
  house: House,
  hint: Hint,
  refund: Refund,
  complete: Complete,
  finReport: FinReport,
  arrowDropup: ArrowDropup,
  scan: Scan,
  offer: Offer,
  shLogo: ShLogo,
  ticket: Ticket,
  x: X,
  video: Video,
  image: Image,
  poster: Poster,
  seat: Seat,
  checked: Checked,
};
