export enum USER_SETTING_STEPS_ENUM {
  ORGANISER_DETAILS = 'ORGANISER_DETAILS',
  BANK_DETAILS = 'BANK_DETAILS',
}

export enum CREATE_EVENT_FORM_STEPS_ENUM {
  BASIC_INFO = 'BASIC_INFO',
  DETAILS = 'DETAILS',
  TICKETS = 'TICKETS',
  SECTION_LIST = 'SECTION_LIST',
  TAXES = 'TAXES',
  PERMISSIONS = 'PERMISSIONS',
  PROMOTE = 'PROMOTE',
  PUBLISH = 'PUBLISH',
}

export enum SHOW_NAVMENU_STEPS_ENUM {
  BASIC_INFO = 'BASIC_INFO',
  DETAILS = 'DETAILS',
  TICKETS = 'TICKETS',
  TAXES = 'TAXES',
  PROMOTE = 'PROMOTE',
  PUBLISH = 'PUBLISH',
}

export enum SEATED_SHOW_NAVMENU_STEPS_ENUM {
  BASIC_INFO = 'BASIC_INFO',
  DETAILS = 'DETAILS',
  TICKETS = 'TICKETS',
  SECTION_LIST = 'SECTION_LIST',
  TAXES = 'TAXES',
  PROMOTE = 'PROMOTE',
  PUBLISH = 'PUBLISH',
}

export enum SHOW_NAVMENU_PUBLISHED_STEPS_ENUM {
  BASIC_INFO = 'BASIC_INFO',
  DETAILS = 'DETAILS',
  TICKETS = 'TICKETS',
  TAXES = 'TAXES',
  PROMOTE = 'PROMOTE',
}

export const eventMenuSubarrayMap = {
  BASIC_INFO: ['Basic Info', 'LOCATION', 'DATE', 'DISCOVERABILITY'],
  DETAILS: ['Details', 'EVENT IMAGE', 'DESCRIPTION', 'TERMS & CONDITIONS'],
  TICKETS: ['Tickets', 'TICKET TYPES'],
  TAXES: ['Taxes', 'CONTACTS'],
  PROMOTE: ['Promote', 'VIDEO', 'SOCIAL MEDIA'],
  PUBLISH: ['Publish'],
};

export const seatedEventMenuSubarrayMap = {
  BASIC_INFO: ['Basic Info', 'LOCATION', 'DATE', 'DISCOVERABILITY'],
  DETAILS: ['Details', 'EVENT IMAGE', 'DESCRIPTION', 'TERMS & CONDITIONS'],
  TICKETS: ['Tickets', 'TICKET TYPES'],
  SECTION_LIST: ['Seating Plan'],
  TAXES: ['Taxes', 'CONTACTS'],
  PROMOTE: ['Promote', 'VIDEO', 'SOCIAL MEDIA'],
  PUBLISH: ['Publish'],
};

export type PaymentGatewayType = {
  settingType: string;
  value: string;
  gatewayType: string;
};
