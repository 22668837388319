import { FC, useContext, useState } from 'react';

import { InputNumber } from 'antd';
import 'antd/lib/input-number/style/index';
import { t } from 'tools/i18n';
import { Checkbox, Form, Label, Select, Switch, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import {
  EVENT_CREATE_FORM_DETAILS_ENUM,
  createEventFormDetailsContext,
} from '../eventCreateFormDetails.utils';
import s from './EventCreateFormDetailsAges.module.scss';
import { minimumAges, requiredAges } from './eventCreateFormDetailsAges.utils';

const EventCreateFormDetailsAges: FC = () => {
  const { form } = useContext(createEventFormDetailsContext);
  const [accessibilityTicket, setAccessibilityTicket] = useState<boolean>(false);
  const accessibleTicketsAvailable = Form.useWatch(
    EVENT_CREATE_FORM_DETAILS_ENUM.ACCESSIBLE_TICKETS_AVAILABLE,
    form,
  );
  const childTicketRequired = Form.useWatch(
    EVENT_CREATE_FORM_DETAILS_ENUM.CHILD_TICKET_REQUIRED,
    form,
  );
  const minimumAge = Form.useWatch(EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES, form);
  const ageLimit = parseInt(minimumAge?.value?.replace(/\D/g, ''), 10) || 0;

  const agesContent = !childTicketRequired ? (
    <div>
      <Label>{t('common_free_to_age')}</Label>
      <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.FREE_UP_TO_AGE} initialValue={5}>
        <InputNumber className={s.input} min={0} max={21} />
      </Form.Item>
    </div>
  ) : (
    <div>
      <Label>{t('common_ages')}</Label>
      <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET}>
        <Select
          fullWidth
          labelInValue
          placeholder="Select ages"
          options={requiredAges.map((key) => ({
            label: key,
            value: key,
          }))}
        />
      </Form.Item>
    </div>
  );

  return (
    <div className={s.wrapper}>
      <div className={s.firstContent}>
        <Label required>{t('common_minimum_age')}</Label>
        <Form.Item
          initialValue={{ value: '5+' }}
          name={EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES}
          rules={[formRules.required]}>
          <Select
            labelInValue
            placeholder="Select age"
            fullWidth
            options={minimumAges.map((key) => ({
              label: key,
              value: key,
            }))}
          />
        </Form.Item>
        {!!minimumAge && ageLimit < 16 && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                marginTop: 16,
                marginBottom: 16,
              }}>
              <Form.Item
                name={EVENT_CREATE_FORM_DETAILS_ENUM.CHILD_TICKET_REQUIRED}
                initialValue={false}>
                <Switch />
              </Form.Item>
              <Typography type="h5">{t('common_child_ticket_required')}</Typography>
            </div>
            {agesContent}
          </>
        )}
        <div style={{ marginTop: 16 }}>
          <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.ACCESSIBLE_TICKETS_AVAILABLE}>
            <Checkbox value={accessibilityTicket} onChange={setAccessibilityTicket}>
              {' '}
              <strong>Accessibility Tickets Available</strong>
            </Checkbox>
          </Form.Item>
          {accessibleTicketsAvailable && (
            <div style={{ marginTop: 16 }}>
              <Label>Accessibility tickets description</Label>
              <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.ACCESSIBLE_TICKETS_DESCRIPTION}>
                <TextArea
                  className={s.accessibilityTicketText}
                  name={EVENT_CREATE_FORM_DETAILS_ENUM.ACCESSIBLE_TICKETS_DESCRIPTION}
                  rows={4}
                  placeholder="Add details"></TextArea>
              </Form.Item>
            </div>
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default EventCreateFormDetailsAges;
