import { Dispatch, FC, SetStateAction } from 'react';

import { DeleteOutlined, EditOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Button, FormInstance, Typography } from 'ui';

import { useDeleteEventDatesMutation } from 'services/events/eventsApiService';

import { MultipleDatesType } from 'types/events/eventTypes';

import s from './MultipleDateList.module.scss';
import { getDateFormat } from './multipleDateList.utils';

interface MultipleDateListProps {
  data: MultipleDatesType[];
  setShowsDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  setEditIndex: Dispatch<SetStateAction<number>>;
  form?: FormInstance;
}

const MultipleDateList: FC<MultipleDateListProps> = (props) => {
  const { data, setShowsDates, setEditIndex, form } = props;
  const [deleteDates] = useDeleteEventDatesMutation();

  const handleDeleteDates = (id: string, i) => {
    setShowsDates((prevState) => prevState?.filter((_, index) => index !== i));
    if (id) {
      deleteDates(id);
    }
  };

  const handleEditDates = (date, i) => {
    form?.setFieldsValue({
      showDates: {
        startDate: date.startDate === '' ? null : dayjs(date.startDate, 'DD-MMM-YYYY'),
        startTime: date.startTime === '' ? null : dayjs(`${date.startDate} ${date.startTime}`),
        doorsOpen: date.doorsOpen === '' ? null : dayjs(`${date.startDate} ${date.doorsOpen}`),
        endDate: date.endDate === '' ? null : dayjs(date.endDate, 'DD-MMM-YYYY'),
        endTime: date.endTime === '' ? null : dayjs(`${date.endDate} ${date.endTime}`),
        isSpecifyEndTime: date.endDate !== '' || date.endTime !== '' ? true : false,
        hoursWhenSalesEnd: date.hoursWhenSalesEnd,
        beforeOrAfterEvent: date.beforeOrAfterEvent,
        isShowHidden: date.isShowHidden,
      },
    });
    setEditIndex(i);
  };

  return (
    <div className={s.wrapper}>
      {data &&
        data.map((date, i) => {
          const startDate = `${date.startDate} ${date.startTime}`;
          const endDate =
            date.endDate !== '' && date.endDate !== 'Invalid Date'
              ? `${date.endDate || dayjs().format('DD-MMM-YYYY')} ${date.endTime || ''}`
              : '';
          const doorsOpen = `${date.startDate} ${date.doorsOpen}`;
          // const hoursWhenSalesEnd = date.hoursWhenSalesEnd;
          // const beforeOrAfterEvent = date.beforeOrAfterEvent;
          // const getEventDuration = () => {
          //   if (date?.endDate && date?.endTime) {
          //     const hours = dayjs(`${date.endDate || date.startDate} ${date.endTime}`).diff(
          //       `${date.startDate} ${date.startTime}`,
          //       'h',
          //     );
          //     const minutes =
          //       dayjs(`${date.endDate || date.startDate} ${date.endTime}`).diff(
          //         `${date.startDate} ${date.startTime}`,
          //         'm',
          //         true,
          //       ) % 60;

          //     return `${hours.toFixed(0)}h ${minutes ? `${minutes}m` : ''}`;
          //   }
          // };

          return (
            <div key={`${date.startDate}.${i}`} className={s.item}>
              <div className={s.itemContent}>
                <div>
                  <div className={s.block}>
                    <Typography type="main" weight={700}>
                      {`${getDateFormat(startDate)} `}
                    </Typography>
                    <Typography type="main" color="grey" weight={500}>
                      at
                    </Typography>
                    <Typography type="main" weight={700}>
                      {dayjs(startDate).format('HH:mm')}
                    </Typography>
                    {endDate !== '' && (
                      <>
                        <Typography type="main" color="grey" weight={500}>
                          to
                        </Typography>
                        <Typography type="main" weight={700}>
                          {`${getDateFormat(endDate)}`}
                        </Typography>
                        <Typography type="main" color="grey" weight={500}>
                          at
                        </Typography>
                        <Typography type="main" weight={700}>
                          {dayjs(endDate).format('HH:mm')}
                        </Typography>
                      </>
                    )}
                  </div>
                  <div className={s.block}>
                    <Typography type="main" color="grey" weight={500}>
                      Doors open at
                    </Typography>
                    <Typography type="main" weight={700}>
                      {dayjs(doorsOpen).format('HH:mm')}
                    </Typography>
                  </div>
                </div>
                <div className={s.actionButtons}>
                  <div className={s.hideButton}>
                    {date.isShowHidden && (
                      <Tooltip placement="top" title="Event is invisible on This Date">
                        <Button className={s.editButton} color="ghost">
                          <EyeInvisibleOutlined /> HIDDEN
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                  <Button
                    color="ghost"
                    className={s.editButton}
                    onClick={() => handleEditDates(date, i)}>
                    <EditOutlined />
                    EDIT
                  </Button>
                  <Button
                    color="ghost"
                    className={s.deleteButton}
                    onClick={() => handleDeleteDates(date?.showId || '', i)}>
                    <DeleteOutlined />
                    DELELTE
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MultipleDateList;
