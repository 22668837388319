import {
  // CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Button, ColorPicker } from 'antd';
import { Collapse, Select } from 'antd';
import { Color } from 'antd/es/color-picker';
import TicketPublicVisibilityCheckbox from 'component/ticket-public-visibility-checkbox';
import { Trans } from 'react-i18next';
import { handleCutString } from 'tools/cutstring';
import { t } from 'tools/i18n';
import { Checkbox, Form, Icon, Input, Label, Modal, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { useAddedTicketTypeMutation, useLazyGetDatesQuery } from 'services/events/eventsApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EventTicketGroupResType, EventTicketType } from 'types/events/eventTypes';

import { EVENT_CREATE_EVENT_TICKET_ENUM } from '../eventCreateFormTickets.utils';
import s from './CreateTicketModal.module.scss';

interface CreateTicketModalProps {
  open: boolean;
  ticketGroups: EventTicketGroupResType[];
  onClose: Dispatch<SetStateAction<boolean>>;
  setTickets: Dispatch<SetStateAction<EventTicketType[]>>;
  activeEditTicket?: EventTicketType;
  setActiveEditTicket: Dispatch<SetStateAction<EventTicketType | undefined>>;
  currency: string;
  eventId: string;
  maxAttendeesAmount?: string;
}

const defaultColors = [
  'rgb(76, 124, 255)',
  'rgb(253, 160, 32)',
  'rgb(220, 57, 65)',
  'rgb(250, 238, 27)',
  'rgb(224, 104, 234)',
  'rgb(39, 170, 225)',
  'rgb(57, 181, 74)',
];

const CreateTicketModal: FC<CreateTicketModalProps> = (props) => {
  const {
    open,
    onClose,
    setTickets,
    ticketGroups,
    currency,
    activeEditTicket,
    setActiveEditTicket,
    eventId,
    maxAttendeesAmount,
  } = props;

  // const { token } = theme.useToken();

  // const panelStyle: React.CSSProperties = {
  //   marginBottom: 24,
  //   backgroundColor: '#fff',

  //   padding: 0,
  //   background: token.colorFillAlter,
  //   borderRadius: token.borderRadiusLG,
  //   border: 'none',
  // };

  const [createTickets, { isLoading }] = useAddedTicketTypeMutation();
  const [getDates] = useLazyGetDatesQuery();
  const [hasNoMaxQuantity, setHasNoMaxQuantity] = useState<boolean>(false);
  const [colorString, setColorString] = useState<string>('rgb(76, 124, 255)');
  const [inputValue, setInputValue] = useState<string>('');
  const [form] = Form.useForm();
  const [selectedColor, setSelectedColor] = useState(defaultColors[0]);

  const dispatch = useAppDispatch();

  const handleColorChange = (color) => {
    setSelectedColor(color);
    setColorString(color);
  };

  const handleClose = useCallback(() => {
    onClose(false);
    form.resetFields();
    setHasNoMaxQuantity(false);

    if (activeEditTicket?.name) {
      setActiveEditTicket(undefined);
    }
  }, [activeEditTicket?.name, form, onClose, setActiveEditTicket]);

  const handleCreateTicket = useCallback(
    async (data) => {
      data.colour = colorString;
      const result = {
        ...data,
        ...(hasNoMaxQuantity && {
          [EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD]: 0,
        }),
      };

      let newTickets: EventTicketType[] = [];

      if (activeEditTicket?.name) {
        setTickets((prev) => {
          const ticketsPrev = prev?.map((ticket, i) => ({
            ...ticket,
            sortOrder: i,
          }));

          newTickets = [
            ...ticketsPrev.filter((ticket) => ticket.name !== activeEditTicket.name),
            { ...activeEditTicket, ...result },
          ];
          console.log(newTickets);
          return newTickets;
        });
      } else {
        setTickets((prev) => {
          if (prev?.length) {
            const ticketsPrev = prev.map((ticket, i) => ({
              ...ticket,
              sortOrder: i,
            }));

            newTickets = [...ticketsPrev, { ...result, sortOrder: ticketsPrev.length }];
          } else {
            newTickets = [{ ...result, sortOrder: 0 }];
          }
          return newTickets;
        });
      }

      if (newTickets.length) {
        const res = await createTickets({
          ticketTypes: newTickets,
          ...(!!maxAttendeesAmount && { maxNumberOfAttendees: Number(maxAttendeesAmount) }),
          eventId: eventId || '',
        });

        if ('data' in res) {
          getDates(eventId!);
          dispatch(setActiveEvent(res?.data));
          setColorString('rgb(76, 124, 255)');
        }
      }

      handleClose();
    },
    [
      activeEditTicket,
      getDates,
      handleClose,
      hasNoMaxQuantity,
      setTickets,
      colorString,
      createTickets,
      dispatch,
      eventId,
      maxAttendeesAmount,
    ],
  );

  // const rgbToHex = (rgb: string | null | undefined) => {
  //   if (rgb === undefined || rgb === null) {
  //     return '#1677ff';
  //   }

  //   const rgbValues = rgb
  //     .substring(4, rgb.length - 1)
  //     .split(',')
  //     .map(Number);

  //   const hexValues = rgbValues.map((value) => {
  //     const hex = value.toString(16).toUpperCase();
  //     return hex.length === 1 ? `0${hex}` : hex;
  //   });

  //   // Concatenate the hexadecimal values
  //   const hexCode = `#${hexValues.join('')}`;

  //   return hexCode;
  // };
  const changeColor = (color: Color, colorString: string) => {
    setColorString(colorString);
  };

  useEffect(() => {
    if (open) {
      form.resetFields([
        EVENT_CREATE_EVENT_TICKET_ENUM.MIN_QTY_PER_ORDER,
        EVENT_CREATE_EVENT_TICKET_ENUM.MAX_QTY_PER_ORDER,
        EVENT_CREATE_EVENT_TICKET_ENUM.SHOW_REMAINING_TICKETS,
        EVENT_CREATE_EVENT_TICKET_ENUM.HIDE_FROM_PUBLIC,
      ]);
    }
  }, [form, open]);

  useEffect(() => {
    if (activeEditTicket) {
      setColorString(activeEditTicket.colour);
      form.setFieldsValue(activeEditTicket);
    }
  }, [activeEditTicket, form]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const ticketGroupsOption = ticketGroups?.map((group) => ({
    value: group.id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={group.imageURL}
          alt={group.name}
          style={{ width: 20, height: 20, marginRight: 8 }}
        />
        <span>{handleCutString(group.name, 20, true)}</span>
      </div>
    ),
  }));

  return (
    <Modal
      style={{ marginTop: 24, marginBottom: 24 }}
      handleClose={handleClose}
      open={open}
      footer={null}
      className={s.modal}>
      <Modal.Header>
        <Typography type="h2">
          {t(
            !activeEditTicket?.name
              ? 'event_create_form_create_ticket'
              : 'event_create_form_edit_ticket',
          )}
        </Typography>
      </Modal.Header>

      <Form form={form} onFinish={handleCreateTicket}>
        <Modal.Content className={s.content}>
          <div>
            <Label>{t('common_visibility')}</Label>
            <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.HIDE_FROM_PUBLIC} initialValue={false}>
              <TicketPublicVisibilityCheckbox />
            </Form.Item>
          </div>
          <div className={s.hint}>
            <div>
              <Icon name="warning" />
            </div>
            <Trans i18nKey="event_create_form_ticket_tip" components={{ b: <b /> }} />
          </div>
          <div className={s.item}>
            <Label required>{t('event_create_form_ticket_name')}</Label>
            <Form.Item
              fullWidth
              name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_NAME}
              rules={[formRules.required]}>
              <Input
                onChange={handleInputChange}
                name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_NAME}
                maxLength={100}
                placeholder="Example: General Admission or VIP"
              />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Typography type="table" weight={600}>
                {`${inputValue.length}/100`}
              </Typography>
            </div>
          </div>

          {/* <div className={s.row}>
            <div className={s.item}>
              <Label required>{t('event_create_max_ticket_capacity')}</Label>
              <Form.Item
                name={EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD}
                fullWidth
                rules={!hasNoMaxQuantity ? [formRules.required] : []}>
                <Input.Number
                  placeholder="0"
                  disabled={hasNoMaxQuantity}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div>
              <Checkbox value={hasNoMaxQuantity} onChange={setHasNoMaxQuantity}>
                <Typography type="main">Ticket type has no max quantity.</Typography>
              </Checkbox>
            </div>
          </div> */}
          <div className={s.price}>
            <Label required>{t('common_price')}</Label>
            <Form.Item
              fullWidth
              name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_PRICE}
              rules={[formRules.required]}>
              <Input.Number currencyMask={currency} style={{ width: '100%' }} placeholder="0.00" />
            </Form.Item>
          </div>
          <div className={s.item}>
            <Label>{t('common_ticket_description')}</Label>
            <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_DESCRIPTION}>
              <TextArea
                className={s.description}
                color="grey"
                name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_DESCRIPTION}
                maxLength={140}
                placeholder="Tell your attendees more about this ticket type."
              />
            </Form.Item>
          </div>

          <div className={s.additionalContent}>
            <div className={s.item}>
              {/* <Typography type="main" weight={600}>
                {t('common_ticket_per_order')}
              </Typography> */}
              <div className={s.quantityContent}>
                {/* <div>
                    <Label>{t('common_minimum_quantity')}</Label>
                    <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.MIN_QTY_PER_ORDER} fullWidth>
                    <Input.Number placeholder="0" style={{ width: '100%' }} />
                    </Form.Item>
                    </div> */}
                <div>
                  <Label>{t('common_maximum_number_of_tickets')}</Label>
                  <Form.Item
                    name={EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD}
                    fullWidth>
                    <Input.Number placeholder="0" style={{ width: '100%' }} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div>
              <Form.Item
                name={EVENT_CREATE_EVENT_TICKET_ENUM.SHOW_REMAINING_TICKETS}
                className={s.content}>
                <Checkbox>
                  <Typography type="main">{t('event_create_tickets_remaining_text')}</Typography>
                </Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className={s.colorPicker}>
            <div>
              {defaultColors.map((color) => (
                <Button
                  key={color}
                  value={color}
                  className={s.Radio}
                  onClick={() => handleColorChange(color)}
                  style={{
                    backgroundColor: color,
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                    display: 'inline-block',
                    margin: '0 5px',
                    textAlign: 'center',
                    lineHeight: '24px',
                    padding: 0,
                    cursor: 'pointer',
                    border: selectedColor === color ? '2px solid #000' : `2px solid ${color}`,
                  }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                    }}></span>
                </Button>
              ))}
            </div>
            <ColorPicker
              value={colorString}
              onChange={changeColor}
              showText={(color) => <span>Ticket Color ({color.toHexString()})</span>}
            />
          </div>
          <Collapse
            expandIconPosition="end"
            style={{ width: '100%' }}
            expandIcon={({ isActive }) =>
              isActive ? <Icon name="arrowDropup" /> : <Icon name="arrowDropdown" />
            }
            bordered={false}
            ghost
            items={[
              {
                key: '1',
                label: 'Extra Settings',
                children: (
                  <div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Label>Min Qty</Label>
                        <Form.Item
                          fullWidth
                          name={[
                            'otherSettings',
                            EVENT_CREATE_EVENT_TICKET_ENUM.MIN_QTY_PER_ORDER,
                          ]}>
                          <Input.Number style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                      </div>
                      <div className={s.price}>
                        <Label>Max Qty</Label>
                        <Form.Item
                          fullWidth
                          name={[
                            'otherSettings',
                            EVENT_CREATE_EVENT_TICKET_ENUM.MAX_QTY_PER_ORDER,
                          ]}>
                          <Input.Number style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Label>Issue type</Label>
                        <Form.Item
                          fullWidth
                          name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.ISSUE_TYPE]}>
                          <Select
                            defaultValue="normal"
                            style={{ width: '100%' }}
                            options={[
                              { value: 'normal', label: 'Normal' },
                              { value: 'addon', label: 'Addon' },
                            ]}
                          />
                        </Form.Item>
                      </div>
                      {ticketGroups && (
                        <div className={s.price}>
                          <Label>Ticket Group</Label>
                          <Form.Item
                            fullWidth
                            name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.GROUP_ID]}
                            initialValue={ticketGroups[0]?.id}>
                            <Select style={{ width: '100%' }} options={ticketGroupsOption} />
                          </Form.Item>
                        </div>
                      )}
                    </div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Label>View type</Label>
                        <Form.Item
                          name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.UI_VIEW_TYPE]}>
                          <Select
                            defaultValue="all"
                            style={{ width: '100%' }}
                            options={[
                              { value: 'all', label: 'All' },
                              { value: 'eventWindow', label: 'EventWindow' },
                              { value: 'kiosk', label: 'Kiosk' },
                              { value: 'checkOut', label: 'CheckOut' },
                            ]}
                          />
                        </Form.Item>
                      </div>
                      <div className={s.price}>
                        <Label>Print view type</Label>
                        <Form.Item
                          name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.PRINT_VIEW_TYPE]}>
                          <Select
                            defaultValue="visible"
                            style={{ width: '100%' }}
                            options={[
                              { value: 'visible', label: 'Visible' },
                              { value: 'notVisible', label: 'Hidden' },
                            ]}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Form.Item
                          name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.IGNORE_LIMITS]}>
                          <Checkbox>Do not add qty to event limit</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Label>Generation type</Label>
                        <Form.Item
                          fullWidth
                          name={['otherSettings', EVENT_CREATE_EVENT_TICKET_ENUM.GENERATION_TYPE]}>
                          <Select
                            defaultValue="seperateTicketsPerTicketType"
                            style={{ width: '100%' }}
                            options={[
                              {
                                value: 'seperateTicketsPerTicketType',
                                label: 'SeperateTicketType',
                              },
                              {
                                value: 'groupTicketByTicketType',
                                label: 'GroupTicketByTicketType',
                              },
                            ]}
                          />
                        </Form.Item>
                      </div>
                      <div className={s.price}>
                        <Label>Barcode type</Label>
                        <Form.Item
                          fullWidth
                          name={[
                            'otherSettings',
                            EVENT_CREATE_EVENT_TICKET_ENUM.BAR_CODE_GENERATION_TYPE,
                          ]}>
                          <Select
                            defaultValue="default"
                            style={{ width: '100%' }}
                            options={[
                              {
                                value: 'default',
                                label: 'Default',
                              },
                              { value: 'importFromBatch', label: 'ImportFromBatch' },
                            ]}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                key: '2',
                label: 'Scan Settings',
                children: (
                  <div>
                    <div className={s.fieldRow}>
                      <div className={s.price}>
                        <Label>Number of allowed scans</Label>
                        <Form.Item fullWidth name={['scanSettings', 'numberOfAllowedScans']}>
                          <Input.Number style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                      </div>
                      <div className={s.price}>
                        <Label>Re-validate after minutes</Label>
                        <Form.Item fullWidth name={['scanSettings', 'minutesToRevalidate']}>
                          <Input.Number style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ width: '50%' }}>
                      <div className={s.price}>
                        <Label>Keep valid for minutes</Label>
                        <Form.Item fullWidth name={['scanSettings', 'keepValidForMinutes']}>
                          <Input.Number style={{ width: '100%' }} placeholder="0" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <div className={s.actions}>
            <Button
              style={{ paddingLeft: 24, paddingRight: 24, fontSize: 14 }}
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}>
              {activeEditTicket?.name ? 'SAVE CHANGES' : 'ADD TICKET'}
            </Button>
            <Button
              style={{ paddingLeft: 24, paddingRight: 24, fontSize: 14 }}
              color="grey"
              size="large"
              onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default CreateTicketModal;
