import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Button, Modal } from 'antd';
import ReactQuill from 'react-quill';
import { t } from 'tools/i18n';
import { Icon, Label, Typography } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setIsEditingEvent, setSubStep } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import s from './EventCreateFormDetailsTerms.module.scss';

interface EventCreateDetailProps {
  setPromoterTerms: Dispatch<SetStateAction<string | undefined>>;
  promoTerms: string | undefined;
  shTerms: string | undefined;
  setSHTerms: Dispatch<SetStateAction<string | undefined>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const EventCreateFormDetailsTerms: FC<EventCreateDetailProps> = (props) => {
  const { setPromoterTerms, promoTerms, setSHTerms, shTerms, setIsDirty } = props;
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const hasAdminRole = currentUser?.roles?.find((role) => role.roleCode === 'ADM');
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const handleFocus = () => {
    setIsDirty(true);
    dispatch(setIsEditingEvent(true));
    dispatch(setSubStep('TERMS & CONDITIONS'));
  };

  const handleSHFocus = () => {
    setIsDirty(true);
    dispatch(setIsEditingEvent(true));
  };

  return (
    <div className={s.wrapper}>
      <div className={s.form}>
        <div className={s.contentBlock}>
          <Typography type="h3" weight={700}>
            Terms & Conditions
          </Typography>
          <div className={s.block} style={{ marginTop: 16 }}>
            <Label>{t('field_terms_and_conditions')}</Label>

            <ReactQuill
              onChange={setPromoterTerms}
              onFocus={handleFocus}
              value={promoTerms}
              theme="snow"
              className={s.editor}
              placeholder={t('event_create_form_full_description_placeholder')}
            />
          </div>

          <div className={s.block} style={{ marginTop: 24 }}>
            <Label>{t('field_sh_terms_and_conditions')}</Label>

            {hasAdminRole ? (
              <ReactQuill
                theme="snow"
                onChange={setSHTerms}
                onFocus={handleSHFocus}
                value={shTerms}
                className={s.editor}
                placeholder={t('event_create_form_full_description_placeholder')}
                readOnly={!hasAdminRole}
              />
            ) : (
              <div className={s.terms}>
                <ul>
                  <li>
                    You acknowledge that ShowsHappening is a booking platform and is not authorised
                    to issue refunds unless instructed to do so by the Organiser/Promoter/Venue. In
                    the case of a refund being issued for tickets bought, refund will be issued on
                    the ticket face value (excluding booking fee or charge). If you opted for a
                    Refundable Booking the terms and conditions found at{' '}
                    <a href="refundable.me/extended/en">refundable.me/extended/en</a> shall apply.
                  </li>
                  <li>
                    You acknowledge that ShowsHappening provides a payment method where payment is
                    collected on behalf of the Organiser/Promoter/Venue as duly authorised, however
                    your contractual relationship other than for the booking fee and the service we
                    offer shall be with the Organiser/Promoter/Venue. ShowsHappening is not
                    responsible for the organisation or staging of the Event and has no control over
                    the cancellation or rescheduling of or for material changes to any Event.
                  </li>
                  <li>
                    ShowsHappening shall provide you with all information and any changes to your
                    ticket as soon as reasonably possible.
                  </li>
                  <li>
                    By completing a purchase, you are confirming that you have read any specific
                    terms and conditions which the Organiser/Promoter/Venue has issued with regard
                    to this event.
                  </li>
                  <li>
                    All tickets or passes should be checked on purchase. Whilst every effort shall
                    be made mistakes cannot always be rectified. Accordingly, please check your
                    Tickets carefully on receipt and contact us immediately if there is a mistake.
                  </li>
                  <li>
                    ShowsHappening is not responsible for the actions or failures of any
                    Organiser/Promoter/Venue. Under no circumstances shall we be liable for death,
                    personal injury, loss or damage to property suffered by you or your guests
                    arising out of attendance at an Event, unless caused by our negligence
                  </li>
                  <li>
                    In the event of any inconsistency with the terms of the
                    Organiser/Promoter/Venue, those of ShowsHappening shall prevail.
                  </li>
                  <li>
                    Information is collected from those registering with{' '}
                    <a href="www.showshappening.com">www.showshappening.com</a> in order to
                    facilitate ticket purchase or other services available. This information is
                    collected and used in accordance with{' '}
                    <a href="https://www.showshappening.com/home/privacy">
                      ShowsHappening&apos;s Privacy Policy
                    </a>
                    , which forms part of these conditions.
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className={s.hintBlock}>
          <div className={s.hint}>
            <div>
              <Icon name="warning" />
            </div>
            <div className={s.hintContent}>
              <p>
                <b>Important. </b>
                Our terms and conditions will also appear next to your terms and conditions on the
                event page. <br />
                To read our Terms and Conditions,{' '}
                <button
                  type="button"
                  className={s.button}
                  onClick={() => setIsDisableModalOpen(true)}>
                  click here
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        closable={false}
        open={isDisableModalOpen}
        zIndex={40}
        footer={[
          <div key="batchAction" className={s.batchModalButton}>
            <Button
              key="back"
              type="primary"
              style={{ width: '100%' }}
              onClick={() => {
                setIsDisableModalOpen(false);
              }}>
              Ok
            </Button>
          </div>,
        ]}>
        <Typography type="main">
          <ul style={{ padding: 16 }}>
            <li>
              You acknowledge that ShowsHappening is a booking platform and is not authorised to
              issue refunds unless instructed to do so by the Organiser/Promoter/Venue. In the case
              of a refund being issued for tickets bought, refund will be issued on the ticket face
              value (excluding booking fee or charge). If you opted for a Refundable Booking the
              terms and conditions found at{' '}
              <a href="refundable.me/extended/en">refundable.me/extended/en</a> shall apply.
            </li>
            <li>
              You acknowledge that ShowsHappening provides a payment method where payment is
              collected on behalf of the Organiser/Promoter/Venue as duly authorised, however your
              contractual relationship other than for the booking fee and the service we offer shall
              be with the Organiser/Promoter/Venue. ShowsHappening is not responsible for the
              organisation or staging of the Event and has no control over the cancellation or
              rescheduling of or for material changes to any Event.
            </li>
            <li>
              ShowsHappening shall provide you with all information and any changes to your ticket
              as soon as reasonably possible.
            </li>
            <li>
              By completing a purchase, you are confirming that you have read any specific terms and
              conditions which the Organiser/Promoter/Venue has issued with regard to this event.
            </li>
            <li>
              All tickets or passes should be checked on purchase. Whilst every effort shall be made
              mistakes cannot always be rectified. Accordingly, please check your Tickets carefully
              on receipt and contact us immediately if there is a mistake.
            </li>
            <li>
              ShowsHappening is not responsible for the actions or failures of any
              Organiser/Promoter/Venue. Under no circumstances shall we be liable for death,
              personal injury, loss or damage to property suffered by you or your guests arising out
              of attendance at an Event, unless caused by our negligence
            </li>
            <li>
              In the event of any inconsistency with the terms of the Organiser/Promoter/Venue,
              those of ShowsHappening shall prevail.
            </li>
            <li>
              Information is collected from those registering with{' '}
              <a href="www.showshappening.com">www.showshappening.com</a> in order to facilitate
              ticket purchase or other services available. This information is collected and used in
              accordance with{' '}
              <a href="https://www.showshappening.com/home/privacy">
                ShowsHappening&apos;s Privacy Policy
              </a>
              , which forms part of these conditions.
            </li>
          </ul>
        </Typography>
      </Modal>
    </div>
  );
};

export default EventCreateFormDetailsTerms;
