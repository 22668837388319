import React, { FC, useCallback, useEffect, useState } from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadProps } from 'antd/es/upload/interface';
import 'antd/lib/modal/style/index';
import 'antd/lib/slider/style/index';
import cn from 'classnames';
import { getBase64 } from 'tools/base64';
import { convertBytes } from 'tools/convertImageSize';
import { handleCutString } from 'tools/cutstring';
import { extractFlyerName } from 'tools/extractFlyerName';
import { getImageFormatFromBase64 } from 'tools/getImageType';
import { t } from 'tools/i18n';
import { Icon, Typography, message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useUploadEventFlyerMutation } from 'services/events/eventsApiService';
import { setActiveEvent, setEventFlyer } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import s from './EventCreateFormDetailsUploadImage.module.scss';

const { Dragger } = Upload;

const EventCreateFormDetailsUploadImage: FC = () => {
  const dispatch = useAppDispatch();
  const [imageObj, setImageObj] = useState<{
    name?: string;
    url?: string;
    size?: string;
    format?: string;
    base64?: string;
  }>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [loadFlyer, setLoadFlyer] = useState<{ loading?: boolean; done?: boolean }>();
  const [uploadText, setUploadText] = useState<string>(t('common_uploading'));
  const [uploadFlyer] = useUploadEventFlyerMutation();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);

  const props: UploadProps = {
    onRemove: () => {
      setImageObj(undefined);
    },
    beforeUpload: (file) => {
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        message.error('The image size must be less than 2 MB.');
        return false;
      }
      getBase64(file, (url) => {
        const decodedInfo = getImageFormatFromBase64(url);

        setLoadFlyer({ loading: true, done: false });
        setImageObj({
          name: file.name,
          url,
          size: convertBytes(file.size),
          format: `image/${decodedInfo?.format}`,
          base64: url,
        });
        dispatch(setEventFlyer(url));
        setTimeout(() => {
          setLoadFlyer({ loading: false, done: true });
          setTimeout(() => setUploadText(t('common_completed')), 2500);
        }, 1000);
      });

      return false;
    },
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    hasControlInside: undefined,
  };
  const handleDeleteLogo = () => {
    setLogoUrl('');
    setLoadFlyer({});
    setImageObj({});
  };

  const handleFlyerUpload = useCallback(async () => {
    if (imageObj?.base64) {
      const res = await uploadFlyer({
        eventId: activeEvent?.eventId || '',
        flyerBase64: imageObj?.base64,
        extension: imageObj?.format,
      });

      if ('data' in res) {
        const updatedData = {
          ...res.data,
          media: {
            ...res.data.media,
            flyer: res.data.media.flyer
              ? `${res.data.media.flyer}?v=${Math.random().toString(36).slice(2, 11)}`
              : null,
          },
        };
        dispatch(setActiveEvent(updatedData));
      }
    }
  }, [activeEvent?.eventId, dispatch, imageObj?.base64, imageObj?.format, uploadFlyer]);

  useEffect(() => {
    if (imageObj?.url) {
      setLogoUrl(imageObj.url);
      handleFlyerUpload();
    } else {
      setLogoUrl(undefined);
    }
  }, [handleFlyerUpload, imageObj, setLogoUrl]);

  useEffect(() => {
    if (activeEvent?.media?.flyer) {
      setLogoUrl(activeEvent?.media?.flyer);
    }
  }, [activeEvent]);

  return (
    <div>
      <div className={s.detailHeader}>
        <Typography type="h2" className={s.title}>
          Details
        </Typography>

        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {extractFlyerName(activeEvent?.media.flyer! || '') ? (
            <img src={activeEvent?.media.flyer!} alt="flyer" className={s.img} />
          ) : (
            <div className={s.emptyFlyer}>
              <Icon name="littleLogo" />
            </div>
          )}
          <Typography type="h4">{activeEvent?.basicInfo.eventName}</Typography>
        </div>
      </div>

      <div className={s.block}>
        <ImgCrop
          modalProps={{ okButtonProps: { block: true }, cancelButtonProps: { block: true } }}
          aspect={1 / 1.414}
          aspectSlider
          rotationSlider={false}
          quality={1}>
          <Dragger {...props} className={s.dragger}>
            {logoUrl ? (
              <img src={logoUrl} alt="flyer" className={s.image} />
            ) : (
              <div className={s.hint}>
                <Icon name="poster" size={60} />
                <Typography type="h3" className={s.uploadHint}>
                  Add a poster
                </Typography>
                <Typography type="small" className={s.uploadHintType}>
                  {t('setting_upload_logo_type')}
                </Typography>
              </div>
            )}
          </Dragger>
        </ImgCrop>

        {imageObj?.url && (
          <div className={s.uploadWrapper}>
            <div>
              <Typography type="main" className={s.uploadText}>
                {t(uploadText)}
              </Typography>
              <Typography type="small" color="grey">
                {handleCutString(imageObj?.name || '')} - {imageObj?.size}
              </Typography>
              <div className={cn(s.upload)}>
                <div
                  className={cn(s.content, {
                    [s.fill]: loadFlyer?.loading,
                    [s.done]: loadFlyer?.done,
                  })}
                />
              </div>
            </div>

            <button onClick={handleDeleteLogo} className={s.delete}>
              <Icon name="trashBox" size={18} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCreateFormDetailsUploadImage;
