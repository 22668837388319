import { FC, useCallback, useMemo } from 'react';

import {
  CopyOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UngroupOutlined,
} from '@ant-design/icons';
import { Modal } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getNotificationRoute } from 'routes/core/list';
import { Button, Icon, Typography } from 'ui';

import { useCloneEventMutation, useDeleteEventMutation } from 'services/events/eventsApiService';
// import { useDeleteEventMutation } from 'services/events/eventsApiService';
import {
  setActiveEventName,
  setEditingEventId,
  setEditingPromoterId,
  setEventNotifications,
} from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EVENT_STATUS_CONFIG } from 'pages/events/event-list-block-view/event-item-tools/eventItemTools.utils';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventWithMetricsType } from 'types/events/eventTypes';

import { DASHBOARD_ROUTE } from '../../../../routes/dashboard/list';
import {
  getAttendeesRoute,
  getCouponsRoute,
  getEditEventFormRoute,
  getEditEventPublishRoute,
  getPermissionRoute, // getEventOfferRoute,
  getScanningRoute,
  getTaxReportRoute,
  getZreadingRoute,
} from '../../../../routes/events/list';
import { TICKETS_ORDERS_ROUTE } from '../../../../routes/tikets/list';
import s from './EventItemsTools.module.scss';

const { confirm } = Modal;
interface EventItemsToolsProps {
  open: boolean;
  event?: EventWithMetricsType;
}

const EventItemsTools: FC<EventItemsToolsProps> = ({ open, event }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cloneEvent] = useCloneEventMutation();
  const [deleteEvent, { isLoading: deleting }] = useDeleteEventMutation();
  // const [deleteEvent] = useDeleteEventMutation();
  const goToEdit = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getEditEventFormRoute(event?.eventId));
    }
  }, [dispatch, event, navigate]);

  const showDeleteConfirm = useCallback(() => {
    confirm({
      title: <>Do you want to delete this {event?.eventName} Event?</>,
      icon: <ExclamationCircleOutlined style={{ fontSize: 32 }} />,
      content: <>This action cannot be undone.</>,
      okText: 'Yes',
      okType: 'danger',
      centered: true,
      mask: true,
      onOk() {
        deleteEvent(event?.eventId!);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }, [deleteEvent, event]);

  const goToPublish = useCallback(() => {
    const status = {
      publish: ['draft', 'acceptedTerms'].includes(event?.status || ''),
      pause: ['published', 'approved'].includes(event?.status || ''),
      replay: ['paused'].includes(event?.status || ''),
    };

    if (!status.publish && !status.pause && !status.replay) {
      return {};
    } else if (status.publish) {
      navigate(getEditEventPublishRoute(event?.eventId!));
    }
  }, [navigate, event]);

  // const deleteEventHandler = useCallback(() => {
  //   if (event) {
  //     deleteEvent(event?.eventId);
  //   }
  // }, [deleteEvent, event]);

  const getAttendeesHandler = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getAttendeesRoute(event?.eventId));
    }
  }, [event, dispatch, navigate]);

  const getZreadingHandler = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getZreadingRoute(event?.eventId));
    }
  }, [navigate, dispatch, event]);

  const getScanningHandler = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getScanningRoute(event?.eventId));
    }
  }, [navigate, dispatch, event]);

  // const getEventOfferHandler = useCallback(() => {
  //   if (event) {
  //     navigate(getEventOfferRoute(event?.eventId));
  //   }
  // }, [navigate, event]);

  const getTaxReportHandler = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getTaxReportRoute(event?.eventId));
    }
  }, [navigate, dispatch, event]);

  const goToPermissions = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      dispatch(setActiveEventName(event?.eventName));
      navigate(getPermissionRoute(event?.eventId));
    }
  }, [navigate, dispatch, event]);

  const showConfirm = async () => {
    confirm({
      title: 'Do you want to clone this event?',
      icon: <ExclamationCircleOutlined />,
      content:
        'When cloning an event its ticket types, additional settings, extra terms, its schedule, and all its extra fields, are all copied to a new event with the same name with a copy text added to the end of the event name. All the event show dates will also be cloned.',
      centered: true,
      mask: false,
      async onOk() {
        if (event) {
          try {
            const res = await cloneEvent(event?.eventId);

            if ('data' in res) {
              dispatch(setEditingEventId(res.data?.eventId));
              navigate(getEditEventFormRoute(res.data?.eventId));
            }
          } catch (error) {
            console.error('Error cloning event:', error);
          }
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const goToManageSeats = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(
        `${getEditEventFormRoute(event?.eventId)}?step=${
          CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST
        }`,
      );
    }
  }, [dispatch, event, navigate]);

  const goToReports = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(`${DASHBOARD_ROUTE}?event=${event.eventId}`);
    }
  }, [event, dispatch, navigate]);

  const goToTicketsOrders = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(`${TICKETS_ORDERS_ROUTE}?event=${event.eventId}`);
    }
  }, [event, dispatch, navigate]);

  const goToCoupons = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getCouponsRoute(event?.eventId));
    }
  }, [event, dispatch, navigate]);

  const goToNotifications = useCallback(() => {
    if (event) {
      navigate(getNotificationRoute(event?.eventId));
      dispatch(setEventNotifications([]));
      dispatch(setActiveEventName(event?.eventName));
      dispatch(setEditingPromoterId(event?.promoter?.promoterId));
    }
  }, [event, dispatch, navigate]);

  const buttonSettings: { title?: string; className?: string; type?: string; icon?: string } =
    useMemo(() => {
      const status = {
        publish: ['draft', 'acceptedTerms'].includes(event?.status || ''),
        pause: ['published', 'approved'].includes(event?.status || ''),
        replay: ['paused'].includes(event?.status || ''),
      };

      if (!status.publish && !status.pause && !status.replay) {
        return {};
      } else if (status.publish) {
        return EVENT_STATUS_CONFIG.publish;
      } else if (status.pause) {
        return EVENT_STATUS_CONFIG.pause;
      } else {
        return EVENT_STATUS_CONFIG.replay;
      }
    }, [event]);

  if (!open) {
    return null;
  }

  const handleCloneEvent = () => {
    showConfirm();
  };

  const handleDeleteEvent = () => {
    showDeleteConfirm();
  };

  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Event Setup
        </Typography>
        <div className={s.actions}>
          <Button color="text" className={s.action} onClick={goToEdit}>
            <div className={s.icon}>
              <Icon name="editEvent" />
            </div>
            <Typography className={s.text} type="main">
              Edit Event
            </Typography>
          </Button>
          <Button color="text" className={s.action} onClick={goToPermissions}>
            <div className={s.icon}>
              <Icon name="user" size={20} />
            </div>
            <Typography className={s.text} type="main">
              Manage Permission
            </Typography>
          </Button>
          <Button color="text" className={s.action} onClick={handleCloneEvent}>
            <div className={s.icon}>
              <CopyOutlined style={{ fontSize: 16 }} />
            </div>
            <Typography className={s.text} type="main">
              Clone Event
            </Typography>
          </Button>
          <Button
            className={s.action}
            loading={deleting}
            color="secondary"
            onClick={handleDeleteEvent}>
            <div className={s.icon}>
              <DeleteOutlined style={{ fontSize: 16, color: '#ff4b53' }} />
            </div>
            <Typography className={s.text} type="main">
              <span style={{ color: '#ff4b53' }}>Delete Event</span>
            </Typography>
          </Button>

          {event && event.seatedStandingType === 'seated' && (
            <Button color="text" className={s.action} onClick={goToManageSeats}>
              <div className={s.icon}>
                <UngroupOutlined />
              </div>
              <Typography className={s.text} type="main">
                Manage Seats
              </Typography>
            </Button>
          )}

          {!!buttonSettings.type &&
            (buttonSettings.type === 'publish' || buttonSettings.type === 'approved') && (
              <Button
                color="text"
                className={cn(s.action, s[buttonSettings?.className || ''])}
                onClick={goToPublish}>
                <div className={s.icon}>
                  <Icon name={buttonSettings?.icon || ''} size={20} />
                </div>
                <Typography className={s.text} type="main">
                  {buttonSettings.title}
                </Typography>
              </Button>
            )}
          {/* <button className={cn(s.action, s.delete)} onClick={deleteEventHandler}>
            <div className={s.icon}>
              <Icon name="trashBox" size={16} />
            </div>
            <Typography className={s.text} type="main">
              Delete Event
            </Typography>
          </Button> */}
        </div>
      </div>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Ticket Sales
        </Typography>
        <div className={s.actions}>
          <Button color="text" className={cn(s.action)} onClick={goToTicketsOrders}>
            <div className={s.icon}>
              <Icon name="ticketOrders" size={18} />
            </div>
            <Typography className={s.text} type="main">
              Ticket Orders
            </Typography>
          </Button>
        </div>
      </div>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Promotion Tools
        </Typography>
        <div className={s.actions}>
          <Button color="text" className={s.action} onClick={goToCoupons}>
            <div className={s.icon}>
              <Icon name="coupon" size={16} />
            </div>
            <Typography className={s.text} type="main">
              Coupons
            </Typography>
          </Button>
          {/* <button className={s.action} onClick={getEventOfferHandler}>
            <div className={s.icon}>
              <Icon name="offer" size={20} />
            </div>
            <Typography className={s.text} type="main">
              Event Offers
            </Typography>
          </Button> */}
        </div>
        <div className={s.actions}>
          <Button color="text" className={s.action} onClick={goToNotifications}>
            <div className={s.icon}>
              <Icon name="bell" size={32} />
            </div>
            <Typography className={s.text} type="main">
              Notifications
            </Typography>
          </Button>
        </div>
      </div>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Reporting Tools
        </Typography>
        <div className={s.actions}>
          <Button color="text" className={cn(s.action)} onClick={goToReports}>
            <div className={s.icon}>
              <Icon name="report" />
            </div>
            <Typography className={s.text} type="main">
              Event Report
            </Typography>
          </Button>
          <Button color="text" className={cn(s.action)} onClick={getAttendeesHandler}>
            <div className={s.icon}>
              <Icon name="users" />
            </div>
            <Typography className={s.text} type="main">
              Event Attendees
            </Typography>
          </Button>
          <Button color="text" className={cn(s.action)} onClick={getZreadingHandler}>
            <div className={s.icon}>
              <Icon name="finReport" />
            </div>
            <Typography className={s.text} type="main">
              Financial Report
            </Typography>
          </Button>
          <Button color="text" className={cn(s.action)} onClick={getScanningHandler}>
            <div className={s.icon}>
              <Icon name="scan" />
            </div>
            <Typography className={s.text} type="main">
              Scanning Report
            </Typography>
          </Button>
          <Button color="text" className={cn(s.action)} onClick={getTaxReportHandler}>
            <div className={s.icon}>
              <Icon name="taxReport" />
            </div>
            <Typography className={s.text} type="main">
              Tax Report
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EventItemsTools;
