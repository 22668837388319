import { FC, useCallback, useEffect, useState } from 'react';

import { Tag } from 'antd';
import { Icon, Typography } from 'ui';

import { EventTagType } from 'types/events/eventTypes';

import s from './Checkable.module.scss';

const { CheckableTag: CheckableTagAntd } = Tag;

interface CheckableTagProps {
  value?: EventTagType[];
  tags: EventTagType[];
  onChange?: (value: EventTagType[]) => void;
  onClick?: () => void;
  primaryTags?: EventTagType[];
  secondaryTags?: EventTagType[];
  myTags?: EventTagType[];
  showAddButton?: boolean;
}

const CheckableTag: FC<CheckableTagProps> = (props) => {
  const { onChange, onClick, showAddButton, value, primaryTags, secondaryTags, myTags } = props;
  const [selectedTags, setSelectedTags] = useState<EventTagType[]>([]);

  const isTagInList = (tag: EventTagType, list: EventTagType[]) => {
    return list?.some((t) => t.name === tag.name);
  };

  const handleChange = useCallback(
    (tag: EventTagType, checked: boolean) => {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t?.name !== tag?.name);
      setSelectedTags(nextSelectedTags);

      if (onChange) {
        onChange(nextSelectedTags);
      }
    },
    [selectedTags, onChange],
  );

  useEffect(() => {
    if (!selectedTags.length && value) {
      setSelectedTags(value);
    }
  }, [selectedTags.length, value]);

  const primaryTagComponents = primaryTags
    ?.filter((tag) => isTagInList(tag, primaryTags!))
    .map((tag) => (
      <CheckableTagAntd
        key={tag.id}
        checked={selectedTags.some((t) => t.name === tag.name)}
        onChange={(checked) => handleChange(tag, checked)}>
        {tag.name}
      </CheckableTagAntd>
    ));

  const myTagComponents = myTags?.map((tag) => (
    <CheckableTagAntd
      key={tag.id}
      checked={selectedTags.some((t) => t.name === tag.name)}
      onChange={(checked) => handleChange(tag, checked)}>
      {tag.name}
    </CheckableTagAntd>
  ));

  const secondaryTagComponents = secondaryTags
    ?.filter((tag) => isTagInList(tag, secondaryTags) && !isTagInList(tag, primaryTags!))
    .map((tag) => (
      <CheckableTagAntd
        key={tag.id}
        checked={selectedTags.some((t) => t.name === tag.name)}
        onChange={(checked) => handleChange(tag, checked)}>
        {tag.name}
      </CheckableTagAntd>
    ));

  return (
    <div>
      {myTags?.length! > 0 && (
        <Typography type="small" className={s.label}>
          Select tags from my custom list
        </Typography>
      )}
      <div className={s.wrapper}>{myTagComponents}</div>
      {primaryTags?.length! > 0 && (
        <Typography type="small" className={s.label}>
          Select tags from primary category the list
        </Typography>
      )}
      <div className={s.wrapper}>{primaryTagComponents}</div>
      {secondaryTags?.length! > 0 && (
        <Typography type="small" className={s.label}>
          Select tags from secondary category list
        </Typography>
      )}
      <div className={s.wrapper}>{secondaryTagComponents}</div>
      {showAddButton && (
        <button className={s.add} onClick={onClick} type="button">
          <Icon name="plus" size={16} />
          <Typography type="small" className={s.addText}>
            Add tag
          </Typography>
        </button>
      )}
    </div>
  );
};

export default CheckableTag;
