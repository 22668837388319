import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useSetQuery } from 'tools/hooks';
import { Form, message } from 'ui';

import {
  SubStepSelector,
  createEventFormActiveEventSelector,
  isEditingEventSelector,
} from 'selectors/eventsSlice.selector';
import { useCreateEventDetailsMutation } from 'services/events/eventsApiService';
import { setActiveEvent, setIsEditingEvent, setShowSubStep } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventCreateFormDetails.module.scss';
import EventCreateFormDetailsAges from './event-create-form-details-ages';
// import EventCreateFormDetailsContacts from './event-create-form-details-contacts';
import EventCreateFormDetailsDescription from './event-create-form-details-description';
// import EventCreateFormDetailsSocials from './event-create-form-details-socials';
import EventCreateFormDetailsTerms from './event-create-form-details-terms';
import {
  initialValue,
  promoterTerms,
} from './event-create-form-details-terms/eventCreateFormDetailsTerms.utils';
import EventCreateFormDetailsUploadImage from './event-create-form-details-upload-image';
import {
  EVENT_CREATE_FORM_DETAILS_ENUM,
  EventCreateFormDetailsListType,
  createEventFormDetailsContext,
  prepareEventCreateFormDetails,
} from './eventCreateFormDetails.utils';

const EventCreateFormDetails: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const isEditing = useAppSelector(isEditingEventSelector);
  const currentSubStep = useAppSelector(SubStepSelector);
  const { eventId } = useParams();
  const [editingList, setEditingList] = useState<EventCreateFormDetailsListType>({});
  const activeCreatedEvent = useAppSelector(createEventFormActiveEventSelector);
  const [addEventDetails, { isLoading }] = useCreateEventDetailsMutation();
  const setQuery = useSetQuery();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isFormInitialized = useRef(false);
  const [promoTerms, setPromoTerms] = useState<string | undefined>();
  const [shTerms, setSHTerms] = useState<string | undefined>();

  const contentRef1 = useRef<HTMLDivElement>(null);
  const contentRef3 = useRef<HTMLDivElement>(null);
  const contentRef4 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Clear any previous timeout
          if (timeoutId) clearTimeout(timeoutId);

          // Set a delay before dispatching the action
          timeoutId = setTimeout(() => {
            dispatch(setShowSubStep(entry.target.id));
          }, 500); // 0.5s delay
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '-80% 0px 0px 0px',
      threshold: 0,
    });

    const elements = [contentRef1.current, contentRef3.current, contentRef4.current];

    elements.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      // Clean up observer and timeout on component unmount
      if (timeoutId) clearTimeout(timeoutId);
      elements.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentSubStep === 'Details') {
      contentRef1?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
    if (currentSubStep === 'EVENT IMAGE') {
      contentRef1?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
    if (currentSubStep === 'DESCRIPTION') {
      contentRef3?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
    if (currentSubStep === 'TERMS & CONDITIONS') {
      contentRef4?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start', // Align at the top of the container
      });
    }
  }, [currentSubStep, contentRef1, contentRef3, contentRef4]);

  const handleChange = useCallback(
    (changedValues, all) => {
      setIsDirty(true);
      setEditingList(all);
      dispatch(setIsEditingEvent(true));
    },
    [dispatch],
  );

  const handleSubmit = useCallback(
    async (data) => {
      if (activeCreatedEvent?.eventId) {
        const res = await addEventDetails({
          eventId: activeCreatedEvent.eventId,
          otherDetails: {
            ...prepareEventCreateFormDetails({
              ...data,
            }),
            promoterTerms: promoTerms,
            showsHappeningTerms: shTerms,
          },
        });

        if ('data' in res) {
          dispatch(setActiveEvent(res.data as EventCreatedType));
          message.success('Details data successfully saved!');
          dispatch(setIsEditingEvent(false));
          if (!eventId) {
            setQuery(CREATE_EVENT_FORM_STEPS_ENUM.TICKETS);
          }
        }
      }
    },
    [activeCreatedEvent, promoTerms, shTerms, addEventDetails, dispatch, setQuery, eventId],
  );

  useEffect(() => {
    if (activeCreatedEvent?.eventId && activeCreatedEvent?.otherDetails) {
      const details = activeCreatedEvent.otherDetails;
      setEditingList(details);
      if (!eventId) {
        if (activeCreatedEvent?.otherDetails?.promoterTerms) {
          setSHTerms(activeCreatedEvent?.otherDetails?.showsHappeningTerms);
          setPromoTerms(activeCreatedEvent?.otherDetails?.promoterTerms);
        } else {
          setPromoTerms(promoterTerms);
          setSHTerms(initialValue);
        }
      } else {
        setSHTerms(activeCreatedEvent?.otherDetails?.showsHappeningTerms);
        setPromoTerms(activeCreatedEvent?.otherDetails?.promoterTerms);
      }
      for (const key in details) {
        if (details[key]) {
          form.setFieldValue(key, details[key]);
        }
      }

      if (details?.minimumAge) {
        form.setFieldValue(EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES, {
          value: details.minimumAge,
        });
      }

      if (details?.agesRequiringTicket) {
        form.setFieldValue(EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET, {
          value: details.agesRequiringTicket,
        });
      }
      isFormInitialized.current = true;
      dispatch(setIsEditingEvent(false));
    }
  }, [activeCreatedEvent, dispatch, eventId, form]);

  useEffect(() => {
    document.querySelector('#main')?.scrollTo(0, 0);
  }, []);

  return (
    <createEventFormDetailsContext.Provider value={{ form }}>
      <div
        className={s.wrapper}
        style={{ marginBottom: isEditing ? '100px' : '0' }}
        ref={contentRef1}
        id="Details">
        <Form form={form} onValuesChange={handleChange} onFinish={handleSubmit}>
          <EventCreateFormDetailsUploadImage />
          <div ref={contentRef3} id="DESCRIPTION">
            <EventCreateFormDetailsDescription />
          </div>
          <EventCreateFormDetailsAges />
          {/* <EventCreateFormDetailsSocials /> */}
          <div ref={contentRef4} id="TERMS & CONDITIONS">
            <EventCreateFormDetailsTerms
              promoTerms={promoTerms}
              setPromoterTerms={setPromoTerms}
              setSHTerms={setSHTerms}
              shTerms={shTerms}
              setIsDirty={setIsDirty}
            />
          </div>
          {/* <EventCreateFormDetailsContacts /> */}
          {/*<EventCreateFormDetailsFaq />*/}
          <FormSubmit
            createEvent={!eventId}
            isDirty={isDirty}
            isEditing={isEditing!}
            editingList={editingList}
            type="eventDetails"
            fetching={isLoading}
          />
        </Form>
      </div>
    </createEventFormDetailsContext.Provider>
  );
};

export default EventCreateFormDetails;
