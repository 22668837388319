import { FC, useEffect, useRef, useState } from 'react';

import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PauseOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
import { extractFlyerName } from 'tools/extractFlyerName';
// import { formatDate } from 'tools/formatDate';
import { formatNumber } from 'tools/formatNumbers';
import { Icon, Typography } from 'ui';

import { createEventFormEditingEventIdSelector } from 'selectors/eventsSlice.selector';
import { useAppSelector } from 'store';

import EventItemsTools from 'pages/events/event-list-block-view/event-item-tools';

import EventsEmptyState from 'containers/events-empty-state';
import EventLoadingState from 'containers/events-loading-state';

import { EventWithMetricsType } from 'types/events/eventTypes';

import { CURRENCIES_SETTING } from '../../../constants/core-constants';
// import EventListActionsDropdown from '../event-list-actions-dropdown';
import s from './EventsListBlockView.module.scss';

dayjs.extend(customParseFormat);

interface EventListBlockViewProps {
  list?: EventWithMetricsType[];
  isLoading: boolean;
  currentEventId: string;
}
const EventListBlockView: FC<EventListBlockViewProps> = (props) => {
  const { list, isLoading, currentEventId } = props;
  // const [isShowedAllDates, setIsShowedAllDates] = useState(false);
  const [itemOpenStates, setItemOpenStates] = useState({});
  const eventRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const activeEditEventId = useAppSelector(createEventFormEditingEventIdSelector);

  const navigate = useNavigate();

  const handleGoReport = (event) => {
    navigate(`/reports?event=${event.eventId}`);
  };

  const handleGoOrder = (event) => {
    navigate(`/tickets-orders?event=${event.eventId}`);
  };

  useEffect(() => {
    Object.entries(itemOpenStates).forEach(([eventId, isOpen]) => {
      const eventRef = eventRefs.current[eventId];
      if (eventRef && isOpen) {
        eventRef.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    });
  }, [itemOpenStates]);

  useEffect(() => {
    if (activeEditEventId) {
      toggleItemOpenState(activeEditEventId);
    }
  }, [activeEditEventId]);

  useEffect(() => {
    if (currentEventId) {
      toggleItemOpenState(currentEventId);
    }
  }, [currentEventId]);

  const toggleItemOpenState = (eventId) => {
    setItemOpenStates((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        if (key !== eventId) {
          newState[key] = false;
        }
      });
      newState[eventId] = !prevState[eventId];
      return newState;
    });
  };

  const formatDate = (input: string) => {
    const dateTimeObj = dayjs(input);

    // Get the day, month, year, and time
    const dayOfWeek = dateTimeObj.format('dddd'); // Day of the week (e.g., Monday)
    const day = dateTimeObj.format('D'); // Day (e.g., 23)
    const month = dateTimeObj.format('MMMM'); // Full month name (e.g., November)
    const year = dateTimeObj.format('YYYY'); // Full year (e.g., 2023)
    const time = dateTimeObj.format('HH:mm'); // 24-hour time (e.g., 12:00)

    // Combine everything into the desired format (without ordinal suffix)
    const formattedDate = `${dayOfWeek} ${day} ${month} ${year} at ${time}`;

    return formattedDate;
  };

  const content = {
    approved: (
      <div
        style={{
          background: '#D4FFF6',
          color: '#02C29A',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <Icon name="ticket" width={14} />
        On Sale
      </div>
    ),
    closed: (
      <div
        style={{
          background: '#FFEAEB',
          color: '#FF4B53',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <CloseOutlined />
        Closed
      </div>
    ),

    draft: (
      <div
        style={{
          background: '#EFF0F5',
          color: '#7F86A4',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <EditOutlined />
        Draft
      </div>
    ),
    published: (
      <div
        style={{
          background: '#D4FFF6',
          color: '#02C29A',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <RocketOutlined />
        Published and waiting for approval
      </div>
    ),
    paused: (
      <div
        style={{
          background: '#FFFBD5',
          color: '#000',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <PauseOutlined />
        Paused
      </div>
    ),
    acceptedTerms: (
      <div
        style={{
          background: '#FFFBD5',
          color: '#000',
          padding: 8,
          paddingTop: 4,
          paddingBottom: 4,
          borderRadius: 6,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          marginTop: 8,
          height: 26,
          fontSize: 14,
        }}>
        <EyeOutlined />
        Accepted terms and conditions
      </div>
    ),
  };
  if (!list?.length) {
    if (isLoading) {
      return (
        <div className={s.loading}>
          <Spin indicator={<EventLoadingState />} />
        </div>
      );
    } else {
      return (
        <div className={s.empty}>
          <EventsEmptyState />
        </div>
      );
    }
  }

  return (
    <div className={s.wrapper}>
      {list?.map((event) => {
        const metricEvent = event?.metrics;
        return (
          <div className={s.eventItemWrapper} key={event.eventId}>
            <div>
              <div className={s.eventItem}>
                {extractFlyerName(event?.eventFlyer) ? (
                  <img src={event?.eventFlyer} alt="eventFlyer" className={cn(s.eventFlyer)} />
                ) : (
                  <div className={s.emptyFlyer}>
                    <Icon name="shLogo" color="white" size={100} />
                  </div>
                )}

                <div className={s.eventDetails}>
                  <div className={s.header}>
                    <div>
                      <Typography type="h3">{event?.eventName}</Typography>

                      {event?.status === 'approved' && event.pastEvent ? (
                        <div
                          style={{
                            background: '#FFEAEB',
                            color: '#FF4B53',
                            padding: 8,
                            paddingTop: 4,
                            paddingBottom: 4,
                            borderRadius: 6,
                            width: 'fit-content',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                            marginTop: 8,
                            fontSize: 14,
                          }}>
                          <ExclamationCircleOutlined />
                          Sales ended at {formatDate(event.salesEndAt)}
                        </div>
                      ) : event?.status === 'scheduled' ? (
                        <div
                          style={{
                            background: '#EFF0F5',
                            color: '#7F86A4',
                            padding: 8,
                            paddingTop: 4,
                            paddingBottom: 4,
                            borderRadius: 6,
                            width: 'fit-content',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                            marginTop: 8,
                            height: 26,
                            fontSize: 14,
                          }}>
                          <Icon name="clock" />
                          Ticket sales start time: {event?.startSellingAt}
                        </div>
                      ) : (
                        content[event?.status] && (
                          <div
                            style={{
                              marginTop: 8,
                              fontSize: 14,
                            }}>
                            {content[event?.status]}
                          </div>
                        )
                      )}
                      {/* <Typography type="main" color="grey">
                      Owner: <b>{event?.promoter?.email}</b>
                    </Typography> */}
                    </div>
                    {/*<EventListActionsDropdown event={event} />*/}
                    <div className={s.actions}>
                      <a href={event?.url} target="_blank" className={cn(s.action, s.view)}>
                        <div className={s.icon}>
                          <EyeOutlined style={{ fontSize: 16 }} />
                        </div>
                        <Typography className={s.text} weight={700} type="main">
                          VIEW EVENT
                        </Typography>
                      </a>
                    </div>
                  </div>
                  <div className={s.locations}>
                    <div>
                      <Typography type="main" weight={500} className={s.dateField}>
                        {event?.allDates}

                        {/* {event?.dates?.length <= 1 ? (
                        dayjs(event?.dates[0]?.date).format('DD MMMM YYYY')
                      ) : (
                        <>
                          {dayjs(event?.dates[0]?.date).format('DD MMMM YYYY')}
                          <button
                            className={s.showButton}
                            onClick={() => setIsShowedAllDates(!isShowedAllDates)}>
                            <Typography type="small" color="grey" className={s.block}>
                              {isShowedAllDates ? 'Hide' : 'Show'} all dates{' '}
                              <Icon
                                name="arrowDropdown"
                                size={14}
                                className={cn({ [s.active]: isShowedAllDates })}
                              />
                            </Typography>
                          </button>
                        </>
                      )} */}
                      </Typography>

                      {/* <div className={s.dates}>
                      {isShowedAllDates &&
                        event?.dates?.slice(1)?.map((date) => (
                          <Typography type="main" weight={500} key={date?.date}>
                            {dayjs(date?.date).format('DD MMMM YYYY')}
                          </Typography>
                        ))}
                    </div> */}
                    </div>
                    <Typography type="h6" color="grey" className={s.dateField}>
                      {/* <div className={s.marker}>
                      <Icon name="mapMarker" size={14} />
                    </div> */}
                      {event?.venue?.name || '-'}
                    </Typography>
                    {event.salesEndAt &&
                      event.status !== 'closed' &&
                      !(event?.status === 'approved' && event.pastEvent) && (
                        <div className={s.SalesEndDate}>
                          <div style={{ color: '#7F86A4' }}>
                            Sales will end at:{' '}
                            <span style={{ fontWeight: 500 }}>{formatDate(event.salesEndAt)}</span>
                            {event?.salesEndAtCountdown && <> ({event?.salesEndAtCountdown})</>}
                          </div>
                        </div>
                      )}
                  </div>
                  <div className={s.metricsBlock}>
                    <div className={s.metric}>
                      <Typography type="main" color="grey" className={s.title}>
                        Total Revenue
                      </Typography>
                      <Typography type="h4" className={s.subtitle}>
                        {CURRENCIES_SETTING[metricEvent?.currency?.toUpperCase() || 'EUR']?.symbol}
                        {formatNumber(metricEvent?.totalSales?.allTime)}
                      </Typography>
                      {!!metricEvent?.totalSales?.today && (
                        <Typography type="main" color="grey" className={s.today}>
                          <span className={s.green}>
                            +{' '}
                            {
                              CURRENCIES_SETTING[metricEvent?.currency?.toUpperCase() || 'EUR']
                                ?.symbol
                            }
                            {formatNumber(metricEvent?.totalSales?.today)} today
                          </span>
                        </Typography>
                      )}
                      <div
                        onClick={() => handleGoReport(event)}
                        className={s.linkButton}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}>
                        <Icon name="ticketReports" />
                        <span style={{ fontSize: 14 }}>VIEW REPORTS</span>
                      </div>
                    </div>
                    <div className={s.metric}>
                      <Typography type="main" color="grey" className={s.title}>
                        Tickets Sold
                      </Typography>
                      <Typography type="h4" className={s.subtitle}>
                        {metricEvent?.totalTicketsSold?.allTime}
                      </Typography>
                      {!!metricEvent?.totalTicketsSold?.today && (
                        <Typography type="main" color="grey" className={s.today}>
                          <span className={s.green}>
                            + {metricEvent?.totalTicketsSold?.today} today
                          </span>
                        </Typography>
                      )}
                      <div
                        onClick={() => handleGoOrder(event)}
                        className={s.linkButton}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}>
                        <Icon name="listView" />
                        <span style={{ fontSize: 14 }}>VIEW ORDERS</span>
                      </div>
                    </div>
                    <div className={cn(s.metric, s.mobileHide)}>
                      <div>
                        <Typography type="main" color="grey" className={s.title}>
                          Complimentary
                        </Typography>
                        <Typography type="h4" className={s.subtitle}>
                          {metricEvent?.totalComplimentariesIssued?.allTime}
                        </Typography>
                        {!!metricEvent?.totalComplimentariesIssued?.today && (
                          <Typography type="main" color="grey" className={s.today}>
                            <span className={s.green}>
                              + {metricEvent?.totalComplimentariesIssued?.today} today
                            </span>
                          </Typography>
                        )}
                      </div>
                      <div className={s.linkButton} onKeyDown={() => {}} role="button" tabIndex={0}>
                        <Icon name="ticketOrders" />
                        <span style={{ fontSize: 14 }}>SEND TICKETS</span>
                      </div>
                    </div>
                    {/*<NavLink to="/dashboard" className={classNames(s.stats)}>*/}
                    {/*  <Icon name="stats" size={24} />*/}
                    {/*  <Typography type="main" weight={500}>*/}
                    {/*    more stats*/}
                    {/*  </Typography>*/}
                    {/*</NavLink>*/}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: 8 }}
              ref={(ref) => {
                eventRefs.current[event.eventId] = ref;
              }}>
              <EventItemsTools
                open={itemOpenStates[event.eventId] || list?.length <= 3}
                event={event}
              />
            </div>
            <button className={s.showButton} onClick={() => toggleItemOpenState(event.eventId)}>
              {!itemOpenStates[event.eventId] ? (
                <>
                  <Icon name="arrowDropdown" />
                  <Typography type="main" weight={700}>
                    TOOLS
                  </Typography>
                </>
              ) : (
                <>
                  <Icon name="arrowDropup" />
                  <Typography type="main" weight={700}>
                    HIDE TOOLS
                  </Typography>
                </>
              )}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default EventListBlockView;
