import { FC, useCallback, useEffect, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Form, message } from 'ui';

import { isPublishingEventSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetUserEventsQuery } from 'services/user-management/userManagementApiService';
import { setEditingEventId, setIsPublishingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import EventPopover from 'containers/event-popover';
import EventsEmptyState from 'containers/events-empty-state';
import EventsLoadingState from 'containers/events-loading-state/eventsLoadingState';

import { UserEventType } from 'types/core/userTypes';
import { EventWithMetricsType } from 'types/events/eventTypes';

// import Pagination from '../../component/pagination';
import s from './Events.module.scss';
import EventListBlockView from './event-list-block-view';
import EventsHeader from './events-header';
import EventsListTableView from './events-list-table-view';
import { EventFilterType, SortByEnum, initialFilter } from './events.utils';

const Events: FC = () => {
  const [form] = Form.useForm();
  const searchEventName = Form.useWatch('eventName', form);
  const [getUserEvents, { data: userEvents, isFetching }] = useLazyGetUserEventsQuery();
  const [sortBy, setSortBy] = useState<SortByEnum>(SortByEnum.PUBLISHED_DATE);
  const [requestFilter, setRequestFilter] = useState<EventFilterType>(initialFilter);
  const [isTableView, setIsTableView] = useState(false);
  const debouncedSearchEventName = useDebounce(searchEventName, 500) as string;
  const dispatch = useAppDispatch();
  const isPublishEvent = useAppSelector(isPublishingEventSelector);
  const [currentEventId, setCurrentEventId] = useState<string>();
  const [events, setEvents] = useState<EventWithMetricsType[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searched, setSearched] = useState<boolean>(false);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  // const showedElements =
  //   Number(userEvents?.events?.length) +
  //   (Number(userEvents?.pageNumber) - 1) * Number(requestFilter.PageSize);

  // const handlePageChange = useCallback((PageNumber: number) => {
  //   setRequestFilter((prevState) => ({
  //     ...prevState,
  //     PageNumber,
  //   }));
  // }, []);

  useEffect(() => {
    if (sortBy) {
      setRequestFilter((prevState) => ({
        ...prevState,
        SortBy: sortBy,
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (debouncedSearchEventName?.length >= 3 && !searched) {
      setHasMore(false);
      setSearching(true);
      getUserEvents({
        IncludeMetrics: true,
        PageNumber: 1,
        PageSize: userEvents?.totalRecords,
        Search: debouncedSearchEventName,
      }).then((response) => {
        if (response?.data) {
          setEvents(response.data.events);
          setSearching(false);
          setSearched(true);
        }
      });
    } else if (searchEventName?.length === 0 && searched) {
      setSearching(true);
      setHasMore(true);
      setRequestFilter(initialFilter);
      getUserEvents({
        IncludeMetrics: true,
        PageNumber: 1,
        PageSize: 7,
        Search: '',
      }).then((response) => {
        if (response?.data) {
          setEvents(response.data.events);
          setSearching(false);
          setSearched(false);
          if (response.data.nextPage === null) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      });
    }
  }, [debouncedSearchEventName, searchEventName, getUserEvents, searched, events, userEvents]);

  useEffect(() => {
    getUserEvents(requestFilter);
  }, [getUserEvents, requestFilter]);

  useEffect(() => {
    if (debouncedSearchEventName?.length > 3) {
      setSearched(false);
    }
  }, [debouncedSearchEventName]);

  useEffect(() => {
    if (!hasLoaded) {
      // Fetch initial data
      getUserEvents(requestFilter).then((response) => {
        if (response?.data) {
          setEvents(response.data.events);
          if (response.data.nextPage === null) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
          setHasLoaded(true); // Set the flag to true after the first load
        }
      });
    }
  }, [getUserEvents, requestFilter, events, hasLoaded]);

  const loadMoreData = () => {
    setSearched(false);
    if (!isFetching) {
      setRequestFilter((prev) => ({
        ...prev,
        PageNumber: prev.PageNumber! + 1,
        PageSize: 7,
      }));
      getUserEvents({ ...requestFilter, PageNumber: requestFilter.PageNumber! + 1 }).then(
        (response) => {
          if (response && response.data && response.data.events) {
            setEvents([...events, ...response.data!.events]);
            if (response.data.nextPage === null) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          }
        },
      );
    }
  };

  useEffect(() => {
    const storedEvent = localStorage.getItem('currentEvent');
    if (storedEvent) {
      setCurrentEventId(JSON.parse(storedEvent).eventId);
    }
  }, []);

  useEffect(() => {
    if (isPublishEvent) {
      message.success('Event successfully submitted for publication!');

      setTimeout(() => {
        dispatch(setIsPublishingEvent(false));
      }, 2000);
    }
  }, [dispatch, isPublishEvent]);

  const handleSetActiveEvent = useCallback(
    (event: UserEventType) => {
      dispatch(setEditingEventId(event?.eventId));
    },
    [dispatch],
  );

  return (
    <div className={s.wrapper}>
      <div className={s.headerRow}>
        <SectionTitle
          label="common_my_events"
          className={s.title}
          counter={userEvents?.totalRecords}
        />
        <EventPopover handleEvent={handleSetActiveEvent} />
      </div>
      <Form form={form}>
        <EventsHeader
          searching={searching}
          sortBy={sortBy}
          setSortBy={setSortBy}
          isBlockView={isTableView}
          setIsBlockView={setIsTableView}
        />
      </Form>
      {isTableView && events?.length > 0 && (
        <div id="scrollableTableDiv" className={s.scrollWrapper}>
          <InfiniteScroll
            dataLength={events.length!}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<EventsLoadingState size={250} />}
            scrollableTarget="scrollableTableDiv">
            <EventsListTableView list={events} />
          </InfiniteScroll>
        </div>
      )}
      {!isTableView && events?.length > 0 && (
        <div id="scrollableDiv" className={s.scrollWrapper}>
          <InfiniteScroll
            dataLength={events.length!}
            next={loadMoreData}
            hasMore={hasMore}
            loader={<EventsLoadingState size={250} />}
            scrollableTarget="scrollableDiv">
            <EventListBlockView
              currentEventId={currentEventId!}
              list={events}
              isLoading={isFetching}
            />
          </InfiniteScroll>
        </div>
      )}
      {!isFetching && events?.length === 0 && (
        <div className={s.empty}>
          <EventsEmptyState />
        </div>
      )}
      {isFetching && events?.length === 0 && !isTableView && (
        <div className={s.loadingWrapper}>
          <EventsLoadingState size={400} />
        </div>
      )}
      {/* {showedElements > 0 && (
        <Pagination
          totalElements={userEvents?.totalRecords}
          showedElements={showedElements}
          currentPage={requestFilter?.PageNumber || 0}
          total={userEvents?.totalPages}
          handlePageChange={handlePageChange}
          handleSizeChange={(size) =>
            setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
          }
        />
      )} */}
    </div>
  );
};

export default Events;
