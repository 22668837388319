import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Checkbox, CheckboxProps } from 'antd';
import countries from 'i18n-iso-countries';
import { t } from 'tools/i18n';
import { Button, Icon, Typography, message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useAcceptTermsAndConditionsMutation,
  usePauseEventMutation,
  useRestartEventMutation,
} from 'services/events/eventsApiService';
import { AcceptTermsAndConditionsPropsType } from 'services/events/evetns.api.type';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import PublishEventModal from 'containers/publish-event-modal/publishEventModal';

import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventCreateFormPublish.module.scss';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const EventCreateFormPublish: FC = () => {
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const dispatch = useAppDispatch();

  const [publishEvent, { isLoading: isPublishLoading }] = useRestartEventMutation();
  const [pauseEvent, { isLoading: isPausing }] = usePauseEventMutation();
  const [openedPublishEventModal, setOpenedPublishEventModal] = useState(false);
  const [getPromoterFullInfo, { data: promoter }] = useLazyGetPromoterInfoQuery();

  const [termsChecked, setTermsChecked] = useState<boolean>(false);

  const [acceptTerms, { isLoading: accepting }] = useAcceptTermsAndConditionsMutation();
  const acceptedTermsPayload = useMemo(
    () => ({
      id: activeEvent?.eventId,
      permitNumber: activeEvent?.taxDetails?.permitNumber,
      vatNumber: promoter?.billingVATNumber,
      promoterCompanyName: promoter?.name,
      promoterAddress: promoter?.address,
      promoterTown: promoter?.town,
      promoterMobileNumber: promoter?.mobileNumber,
      promoterEmail: promoter?.email,
      promoterPostCode: promoter?.postcode,
      ibanNumber: promoter?.ibanNumber,
      bankAccountHolderName: promoter?.bankHolderName,
      chargeVAT: activeEvent?.taxDetails?.permitNumber === null ? false : true,
    }),
    [activeEvent, promoter],
  );

  const handleAcceptTerms: CheckboxProps['onChange'] = useCallback(
    (e) => {
      const isChecked = e.target.checked;

      if (isChecked) {
        if (!promoter?.ibanNumber) {
          message.warning('Please input promoter IBAN number.');
          return;
        }
        acceptTerms(acceptedTermsPayload as AcceptTermsAndConditionsPropsType)
          .then((data) => {
            if ('data' in data) {
              dispatch(setActiveEvent(data.data as EventCreatedType));
              message.success('Accepted terms and condition.');
            }
          })
          .catch((error) => {
            console.error('Failed to accept terms:', error);
            setTermsChecked(false);
          });
      }
    },
    [acceptTerms, dispatch, promoter?.ibanNumber, acceptedTermsPayload],
  );

  const buttonSettings = {
    publish: ['draft', 'acceptedTerms'].includes(activeEvent?.basicInfo?.status || ''),
    pause: ['published', 'approved'].includes(activeEvent?.basicInfo?.status || ''),
    replay: ['paused'].includes(activeEvent?.basicInfo?.status || ''),
  };

  const handlePauseEvent = useCallback(async () => {
    const res = await pauseEvent({ id: activeEvent?.eventId });

    if ('data' in res) {
      message.success('Event successfully paused!');
      dispatch(setActiveEvent(res?.data));
    }
  }, [activeEvent?.eventId, pauseEvent, dispatch]);

  const handlePublishEvent = useCallback(async () => {
    const res = await publishEvent({
      id: activeEvent?.eventId,
    });

    if ('data' in res) {
      message.success('Event successfully restarted!');
      dispatch(setActiveEvent(res?.data));
    }
  }, [activeEvent?.eventId, publishEvent, dispatch]);

  useEffect(() => {
    if (activeEvent?.basicInfo?.status === 'acceptedTerms') {
      setTermsChecked(true);
    }
    if (activeEvent?.basicInfo?.promoterId) {
      getPromoterFullInfo(activeEvent?.basicInfo?.promoterId);
    }
  }, [activeEvent, getPromoterFullInfo]);

  return (
    <div className={s.wrapper}>
      {/* <Typography className={s.title} type="h2" weight={700}>
        Good job, your event: {activeEvent?.basicInfo?.eventName} event is almost live!
      </Typography> */}
      {/* <div className={s.card}>
        <div style={{ display: 'flex', alignItems: 'start', gap: 8 }}>
          <Icon name="warning"></Icon>
          <div>
            <Typography type="main" weight={700}>
              UPDATED MAY 2023
            </Typography>
            <Typography type="main">Please read this important information.</Typography>
          </div>
        </div>
        <Button style={{ fontSize: 12 }}>DOWNLOAD .PDF VERSION</Button>
      </div> */}
      <Typography className={s.subTitle}>
        These are fundamental Terms and Conditions of your relationship with ShowsHappening Ltd.
      </Typography>
      <div style={{ marginTop: 24 }}>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            We&apos;re here to help!
          </Typography>
          <Typography type="main" className={s.text}>
            We will do our utmost to help you with your event, we&apos;ve often been asked to
            quickly do something or to help out with problems and situations. Whatever you need, We
            are here to help ensure everything runs smoothly.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            We&apos;re Reliable
          </Typography>
          <Typography type="main" className={s.text}>
            ShowsHappening is running on Microsoft Azure servers in North Europe we also have a
            mirror site running with a live backup of all data in another country.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            We are your exclusive online ticket seller
          </Typography>
          <Typography type="main" className={s.text}>
            Seeing as there is some healthy competition in our very small market we insist that
            ShowsHappening is your exclusive online ticket seller. Sure, you can sell (non seated,
            physical) tickets as you like, but when it comes to your online ticket partner -
            we&apos;re in it together.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            ShowsHappening Event Fee
          </Typography>
          <Typography type="main" className={s.text}>
            ShowsHappening charges a base Event Fee of 2% (+VAT) of the turnover of sales via
            ShowsHappening. We also charge a 10% including VAT Booking Fee to ticket buyers.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            Promoter Payouts
          </Typography>
          <Typography type="main" className={s.text}>
            ShowsHappening pays promoters the week following the event. I.e. 8-14 days after an
            event. There is also the possibility of having the money going directly into your bank
            account by using your own Stripe. In such case please create a Stripe account, and
            contact us on <a href="mailto:hello@showshappening.com">hello@showshappening.com</a> so
            we can link your Stripe account to us.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            IMPORTANT: Cancellation of event or any major problem (like weather).
          </Typography>
          <Typography type="main" className={s.text}>
            In the case of an event cancellation you are entirely reponsible for the refunds that
            will need to be given to ticket buyers. ShowsHappenng pays out a week after an event to
            ensure that there are no major requests for chargebacks as imposed by Visa, Mastercard,
            the Banks&apos;s and the powers that be. In the case of a cancellation ShowsHappening is
            obliged to refund ticket buyers money.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            Charge Backs
          </Typography>
          <Typography type="main" className={s.text}>
            A chargeback can be made for any purchase made with a credit card up to 120 days after
            the card was used. Any chargeback related to your event will be billed to you even if it
            is after the event is settled. For more information please don&apos;t hesitate to ask
            us.tions. Whatever you need, We are here to help ensure everything runs smoothly.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            Event Details
          </Typography>
          <Typography type="main" className={s.text}>
            Event details are very important (start time, end time, location etc). It is comletely
            your reponsibility to make sure that they are correct.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            VAT details and other tax obligations.
          </Typography>
          <Typography type="main" className={s.text}>
            You are required by law to follow the correct procedures and declare the appopriate VAT
            etc.
          </Typography>
        </div>
        <div className={s.Term}>
          <Typography type="h4" weight={700} className={s.text}>
            Door Access / Ticket Scanning
          </Typography>
          <Typography type="main" className={s.text}>
            Please download our Mobile App from either Google or Apple store. The application you
            need to download is ShowsManager. You can use our mobile app and use your camera phone
            to scan tickets. If you wish to give someone the ability to scan tickets on your behalf
            kindly follow the underneath steps
            <ol style={{ marginLeft: 16, marginTop: 8 }}>
              <li>Login to ShowsHappening</li>
              <li> Go on your event and click on Show Tools</li>
              <li>Go on Manage users</li>
              <li>
                Enter email of the person you wish to give access and make sure the Ticket Scanner
                is chosen from the dropdown
              </li>
              <li>The person you enter should be a showshappening user.</li>
            </ol>
          </Typography>
        </div>
      </div>
      <div className={s.textBlock}>
        <Typography type="h4" weight={700} className={s.termsTitle}>
          Terms and Conditions of Event Organisers using ShowsHappening
        </Typography>
        <Typography type="main" className={s.termMainText}>
          By creating an account and listing your event on Showshappening&apos;s website, you agree
          to the below Event organiser (<strong>Event Organiser</strong>) Standard Terms and
          Conditions (“Standard Terms”), including our{' '}
          <a href="https://www.showshappening.com/home/privacy" target="_blank">
            Privacy Policy.
          </a>
          These Standard Terms, as modified from time to time, will apply to this and any future
          Events. <br></br>
          <br></br>Showshappening (“we”, “our”, “us”, or “<strong>ShowsHappening</strong>”) is an
          online ticketing platform which processes ticket purchases and provides ticketing and
          registration services to Event Organisers for specific Events or a series of Events.{' '}
          Showshappening makes it easy for event organisers and planners (&quot;
          <strong>Event Organisers</strong>&quot;) to set up an event and collect payments with
          respect to the sale of tickets/registrations from users who want to attend such events
          (&quot;<strong>Ticket Buyers</strong>&quot;). Event Oranisers may visit the Site; fill out
          a page about their event, including pricing, location, inventory, etc. Once an Event
          Organiserorganiser has set up event Showshappening will collect the fees from Ticket
          Buyers and these funds will then be distributed, less any fees to the Organisers.<br></br>
          <br></br>
          Showshappening is independent and not associated with any Event Organiser, venue, service
          provider, producer, or any other entity or individual supplying goods or services to Event
          Organisers. Showshappening bears no responsibility and cannot be held accountable for any
          actions, omissions, or incidents connected to or resulting from an Event or services
          listed by an Event Organiser on our website. For direct inquiries, you can contact the
          Showshappening at: <br></br>
          <br></br>www.showshappening.com which is fully owned and operated by ShowsHappening Ltd.
          (C60339). Level 3, Goldfield House, Triq Dun Karm, Birkirkara, BKR 9030.
          hello@showshappening.com <br></br>
          <br></br>
          <strong>Event Organiser</strong> () (“you” or “Event Organiser”) refers to you and any
          individual or entity associated with any account created on the ShowsHappening website.
          This includes anyone for whom an Event is listed on our website or for whom tickets are
          issued or sold through the ShowsHappening website to Ticket Buyers, as well as any
          authorised agents, including those you engage to provide goods or services in relation to
          your Event.<br></br>
          <br></br>
          <strong> Event</strong> (the “Event” or your “Event”) refers to each specific event,
          performance, or service that you list on the ShowsHappening website, including current,
          future, and past events, as well as all services related to any of these events.
          <br></br>
          <br></br>
          <strong>Event Attendee</strong> (the “Ticket Buyer”) includes, without limitation, anyone
          who registers for or purchases tickets to your Event using ShowsHappening, all individuals
          for whom a ticket is issued using ShowsHappening, and all valid ticket holders for your
          Event who used ShowsHappening to get their Tickets. ShowsHappening may, at their sole
          discretion, choose whether a Buyer receives any refund, exchange, replacement, or similar
          benefit regarding a ticket.
          <br></br>
          <br></br>
          <strong>Ticketing Sales Services Agreement</strong> means the Ticketing Sales Services
          Agreement entered into by you and Showshappening. These Standard Terms incorporate the
          terms and obligations of the Ticketing Sales Services Agreement. Any reference to the
          “Standard Terms” includes the terms and obligations in the Ticketing Sales Services
          Agreement.
          <br></br>
          <br></br>
          <Typography type="h4">Authorized Seller; Compliance with Law </Typography>
          The Event Organiser represents and warrants that they are authorised to sell or issue
          tickets for the Event and will comply with all applicable laws in doing so. The Event
          Organiser further represents and warrants that all information provided is true, complete,
          and correct, and will promptly update any information if changes occur.
          <br></br>
          <br></br>
          <strong>Fraudulent representation</strong>, including unlawful ticket sales or
          facilitating such sales, or failure to update significant information by the Event
          Organiser or regarding an Event, is a criminal offence. This authorises ShowsHappening to
          issue full face value refunds to Ticket Holders at the Event Organiser&apos;s expense.
          ShowsHappening may, and likely will, prohibit the Event Organiser from using the
          Shoshappening’s services for the current and any future Events, assist law enforcement in
          prosecuting any fraudulent use of the Showshappening’s services, and seek compensation for
          damages caused by fraudulent representation.
          <br></br>
          <br></br>
          <strong>Ticket and Registration Pricing; Ticket and Service Fees</strong> Unless otherwise
          agreed upon before ticket sales or registrations begin, ShowsHappening provides the Event
          Organiser with ticket sales and registration services at no initial upfront costs or fees.
          The Buyer will pay for the ticket and a booking fee which will be retained or paid to
          Showshappening. Booking fees will be charged in addition to the ticket price unless the
          Event Organiser instructs ShowsHappening to include the booking fee in the ticket price.
          The Event Organiser is solely responsible for setting ticket prices. Generally, the
          booking fees are 10% of the ticket value.
          <br />
          <br />
          <p>
            <strong>Taxes</strong>
          </p>
          <br />
          <p>
            The Event Organiser is solely responsible for payment of any sales, use, transfer,
            value-added, excise, or similar taxes imposed by any local, state, or federal taxing
            authority, or any other taxing authority with jurisdiction. The Event Organiser is also
            solely responsible for instructing ShowsHappening to collect any applicable taxes and
            determining the applicable tax rate. If allowed by applicable law, the Event Organiser
            may choose to include such taxes in the ticket price. Additionally, the Event Organiser
            will ensure that all required tax returns and other documentation related to any such
            tax are prepared and timely filed at its own expense. Upon request by ShowsHappening,
            the Event Organiser will provide its exemption certificate or other proof of tax-exempt
            status. The Event Organiser will indemnify and hold ShowsHappening harmless from and
            against the payment of such taxes and any loss, damage, or other liability suffered by
            ShowsHappening (or asserted against ShowsHappening by any taxing authority) in relation
            to the Event Organiser&apos;s nonpayment of such taxes, as set forth in the
            Indemnification and Hold Harmless provision below.
          </p>
          <br />
          <p>
            <strong>In-Event Ticket Management</strong>
          </p>
          <br />
          <p>
            Here we should first say that the Event Organiser is responsible for downloading our
            ticket scanning app and scanning people themselves and we are not responsible in any way
            other than providing them with working software + printed backup (which is always
            available).
          </p>
          <br />
          <p>
            Upon request, ShowsHappening can provide assistance to the Event Organiser with on-site
            Ticket Management services, which involve handling on-site duties related to the box
            office and admission of Buyers. If the Event Organiser is interested in these
            supplementary services, they should contact ShowsHappening to discuss specific details,
            including requirements and associated costs.
          </p>
          <br />
          <p>
            The rates for ShowsHappening staff will be determined based on the hours,
            responsibilities, and duties required to manage in-event ticket management. These
            services will be itemized in the Event Organiser&apos;s final close-out report. Payments
            related to these services may be invoiced to the Event Organiser following the
            completion of the Event. They may also be deducted as a line item from the Event
            proceeds or retained by ShowsHappening from the funds otherwise due to the Event
            Organiser for up to 90 days after the Event&apos;s completion.
          </p>
          <br />
          <p>
            If the Event is cancelled or rescheduled to a date more than three months after the
            initially scheduled date, ShowsHappening may promptly invoice the Event Organiser
            in-event ticketing fees, costs, and chargebacks, with payment due upon receipt of the
            invoice.
          </p>
          <br />
          <p>
            <strong>Ticket Availability and Venue Capacity</strong>
          </p>
          <br />
          <p>
            The Event Organiser is tasked with determining the number of tickets available for
            purchase for each Event. The Event Organiser acknowledges that the number of attendees
            must comply with legal fire regulations, venue capacity limits, and any other
            restrictions stipulated by city, municipality, county, district, state, and/or federal
            regulations. Should the number of Buyers exceed the allowable limit, the Event Organiser
            agrees to refund the full ticket price, along with any associated ticket, service, and
            other fees, to accommodate those denied entry.
          </p>
          <br />
          <p>
            Furthermore, the Event Organiser agrees to reimburse ShowsHappening for the full ticket
            and service fees of the refunded tickets, as well as cover additional expenses such as
            credit card processing fees and chargeback fees incurred from refunding tickets and
            processing ticket exchanges. ShowsHappening retains the right to withhold all payments
            to the Event Organiser for up to 90 days after the conclusion of an Event if Buyers were
            turned away due to overselling or insufficient capacity.
          </p>
          <br />
          <p>
            <strong>Event Cancellation</strong>
          </p>
          <br />
          <p>
            The Event Organiser undertakes to promptly inform ShowsHappening upon deciding to cancel
            an Event. ShowsHappening will then notify Buyers of the cancellation and reserves the
            right to provide full refunds to the Buyers, encompassing ticket and service fees, for
            the cancelled Event.
          </p>
          <br />
          <p>
            The Event Organiser agrees that in the event of an Event cancellation with full refunds
            issued to Buyers, they will remit to ShowsHappening the complete ticket and service fees
            for the refunded tickets. Additionally, the Event Organiser will reimburse
            ShowsHappening for any additional expenses incurred, including but not limited to credit
            card processing fees and chargeback fees associated with ticket refunds, as well as fees
            for exchanging physical tickets.
          </p>
          <br />
          <p>
            <strong>Postponed Events</strong>
          </p>
          <br />
          <p>
            The Event Organiser consents to providing refunds to Buyers for postponed Events upon
            the Buyer&apos;s request. Additionally, the Event Organiser acknowledges that in the
            event of an Event being rescheduled, they are obligated to remit to ShowsHappening the
            complete ticket and service fees for the refunded tickets. Moreover, the Event Organiser
            agrees to reimburse ShowsHappening for any supplementary expenses incurred, which may
            include, but are not limited to, credit card processing fees, chargeback fees associated
            with ticket refunds or the re-issuing of updated tickets.
          </p>
          <br />
          <p>
            <strong>Change to Venue or to Event</strong>
          </p>
          <br />
          <p>
            At the request of the Buyer, the Event Organiser commits to offeringproviding refunds in
            the event of a change to the Event venue or any alterations to the Event itself. In such
            instances, the Event Organiser agrees to remit to ShowsHappening the complete ticket and
            service fees for the refunded tickets. Additionally, the Event Organiser undertakes to
            reimburse ShowsHappening for any associated expenses, including but not limited to
            credit card processing fees, chargeback fees linked to ticket refunds, and fees for
            re-issuing of updated tickets.
          </p>
          <br />
          <p>
            <strong>Ticket Refund Policy</strong>
          </p>
          <br />
          <p>
            The Event Organiser acknowledges that ShowsHappening reserves the right to refund up to
            the face value of the ticket or registration purchased through its platform until sales
            for the Event have concluded. ShowsHappening will communicate with the Event Organiser
            regarding any significant or unusual refund requests, including requests to refund
            service fees. The Event Organiser retains the responsibility of paying ShowsHappening
            the full amount of the ticket and service fees for refunded tickets.
          </p>
          <br />
          <p>
            <strong>Account Settlement</strong>
          </p>
          <br />
          <p>
            Following an Event, the Event Organiser will receive a Report detailing Event sales,
            ticket and service fees, charges for additional services utiliszed by the Event
            Organiser, chargebacks, and all other applicable fees and charges. Any unpaid charges
            and amounts owed by the Event Organiser for past or future Events may also be included
            in the Report and deducted from the Event payment due to the Event Organiser. The Event
            Organiser acknowledges and agrees that ShowsHappening reserves the right to withhold any
            and all payments to the Event Organiser for a period of up to 90 days following the
            conclusion of the Event for any reason, including but not limited to holding a reserve
            amount sufficient to cover potential outstanding In-event fees fees and costs, as well
            as chargebacks.
          </p>
          <br />
          <p>
            <strong>Chargebacks</strong>
          </p>
          <br />
          <p>
            The Event Organiser bears responsibility for all chargebacks, chargeback fees, card
            processing fees, and associated expenses arising from any chargeback (and the disputed
            charge) for which the merchant services company rules in favour of the Buyer. In the
            event of a chargeback concerning a purchase related to an Event, ShowsHappening will
            investigate and contest the chargeback. ShowsHappening is not liable for fraudulent
            activity by Buyers.
          </p>
          <br />
          <p>
            <strong>Amounts Owed to ShowsHappening Due to Chargebacks</strong>
          </p>
          <br />
          <p>
            Amounts owed to ShowsHappening due to a chargeback will be invoiced to the Event
            Organiser following the Event&apos;s completion in the Report. These amounts may be
            deducted as a line item from the Event proceeds or retained by ShowsHappening from the
            funds owed to the Event Organiser after the Event&apos;s completion. ShowsHappening may
            retain a reasonable reserve from the funds owed to cover any chargebacks until either 90
            days after the Event&apos;s completion (by which time all chargebacks are expected to be
            received) or 5 days after the merchant services company notifies ShowsHappening of its
            decision on the last outstanding chargeback, whichever is later. However, if the Event
            is cancelled or postponed to a date more than three months after the initially scheduled
            date, any amounts owed to ShowsHappening related to a chargeback may be invoiced to the
            Event Organiser immediately, with payment due upon receipt of the invoice.
          </p>
          <br />
          <p>
            <strong>Insurance</strong>
          </p>
          <br />
          <p>
            The Event Organiser affirms and guarantees that it either possesses or will obtain a
            comprehensive general liability insurance policy that covers the Event. This policy
            should provide coverage for claims involving bodily injury, death, property damage, or
            loss arising from the Event and each associated performance. The policy should have
            limits equal to or exceeding the higher of: (a) the limits mandated by any Venue; or (b)
            €1,000,000 per occurrence and €3,000,000 in the aggregate. Additionally, the Event
            Organiser will include ShowHappening as an additional insured party on their insurance
            policies pertaining to the Event.
          </p>
          <br />
          <p>
            <strong>Intellectual Property; Right to Remove Content</strong>
          </p>
          <br />
          <p>
            The Event Organiser agrees not to utilize any material or content that infringes upon,
            violates, misuses, or misappropriates the intellectual property rights of any other
            individual or entity, as dictated by any relevant local, state, national, or other law,
            rule, or regulation. This encompasses, but is not limited to, refraining from using
            copyrighted or trademarked text or images on the Event Organiser&apos;s Event page
            unless the Event Organiser possesses ownership or appropriate rights to such copyrighted
            or trademarked material or content. Furthermore, the Event Organiser comprehends and
            accepts that ShowsHappening possesses the absolute right (though not the obligation) to
            remove, delete, or take down any intellectual property or other content that is deemed
            to potentially infringe upon the intellectual property rights of another party.
          </p>
          <br />
          <p>
            ShowsHappening does not assert any ownership claims, and the Event Organiser is not
            transferring any ownership rights in their intellectual property or other content. The
            Event Organiser hereby grants a non-exclusive, worldwide, perpetual, irrevocable,
            royalty-free, transferable, sublicensable right and licence to ShowsHappening to access,
            use, reproduce, transmit, adapt, modify, perform, display, distribute, translate,
            publish, and create derivative works based on the Event Organiser&apos;s Event page or
            any of its content, in whole or in part, across any media, for the purpose of providing
            ticketing and registration services. The Event Organiser acknowledges that
            ShowsHappening is not transferring any ownership rights in its intellectual property to
            the Event Organiser and agrees not to copy, reproduce, alter, modify, create derivative
            works, or publicly display any content or material created, used, or maintained by
            ShowsHappening without the prior written permission of ShowsHappening.
          </p>
          <br />
          <p>
            ShowsHappening holds the absolute right (though not the obligation) to remove, delete,
            or take down any content on an Event page or posted by the Event Organiser. This
            encompasses (but is not limited to) pages, content, or material that:
          </p>
          <ul style={{ listStyle: 'disc', paddingLeft: 20 }}>
            <li>
              Attempts to distribute, transfer, resell, rent, lease, or loan ShowsHappening&apos;s
              services to any other party;
            </li>
            <li>
              Is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or
              hateful, or infringes upon the rights of any third party;
            </li>
            <li>
              Infringes upon any patent, trademark, trade secret, copyright, or other proprietary
              rights of any party;
            </li>
            <li>
              Contains sexually explicit images or other content that may be offensive or harmful to
              minors;
            </li>
            <li>
              Promotes illegal activities or physical harm or injury against any group or
              individual;
            </li>
            <li>
              Impersonates any person or entity, or falsely represents your affiliation with a
              person, entity, or Event;
            </li>
            <li>
              Is utilized as part of sending unwanted or unsolicited emails (or &quot;spam&quot;) to
              individuals; or
            </li>
            <li>
              Violates (either intentionally or unintentionally) any applicable local, state, or
              national law or regulation.
            </li>
          </ul>
          <br />
          <p>
            <strong>Access and Interference</strong>
          </p>
          <br />
          <p>
            The Event Organiser agrees not to utilize any device, software, or routine that disrupts
            the correct functioning of the ShowsHappening site, nor will they attempt to disrupt the
            proper operation of ShowsHappening&apos;s maintained site.
          </p>
          <br />
          <p>
            <strong>Data Security & Privacy Policy</strong>
          </p>
          <br />
          <p>
            By creating an account and listing your event on ShowsHappening&apos;s website and when
            you use our ticketing and registration services, you consent to the collection and
            processing of your information and the information of Buyers as described in these
            Standard Terms, the{' '}
            <a
              href="https://www.showshappening.com/home/disclaimer#:~:text=You%20acknowledge%20that%20ShowsHappening%20is,any%20booking%20fee%20or%20charge."
              target="_blank">
              Terms and Conditions for Ticket “Buyers”
            </a>{' '}
            and Conditions, and our{' '}
            <a href="https://www.showshappening.com/home/privacy" target="_blank">
              Privacy Policy.
            </a>
          </p>
          <br />
          <p>
            The Event Organiser will be granted restricted access to the personal information
            gathered by the Buyer. ShowsHappening endeavors to collect the minimum amount of
            personal data and information feasible. We adhere to the General Data Protection
            Regulation (&quot;GDPR&quot;) established by the European Union. ShowsHappening does not
            process financial data and has data sharing agreements with all subcontractors. We
            strive to uphold a safe and secure environment that safeguards the private personal
            information of Event Organisers and Buyers.
          </p>
          <br />
          <p>
            The Event Organiser undertakes to safeguard all personal information received from
            ShowsHappening and to exercise reasonable precautions, at least as stringent as those
            taken to protect personal and private information collected directly from individuals
            and entities, in order to protect the personal information received from ShowsHappening.
            Furthermore, the Event Organiser agrees not to utilize the personal information in any
            manner that contravenes or may contravene any applicable local, European Union, or other
            data privacy laws or statutes. The Event Organiser acknowledges ShowsHappening&apos;s
            adherence to the GDPR and agrees that if they sell or issue tickets to individuals
            protected under the GDPR, they will comply with GDPR transparency, consent, accuracy,
            data deletion, and other requirements. Additionally, the Event Organiser agrees to
            collaborate with ShowsHappening in fulfilling GDPR requirements, including (but not
            limited to) deleting data received from ShowsHappening upon request.
          </p>
          <br />
          <p>
            <strong>Disclaimer Of Warranties</strong>
          </p>
          <br />
          <p>
            You expressly acknowledge that the use of the ShowsHappening services is at your sole
            risk, and all such services are provided on an &quot;as is&quot; and &quot;as
            available&quot; basis. ShowsHappening disclaims all warranties of any kind, whether
            express or implied, including, but not limited to, any warranties of merchantability,
            fitness for a particular use or purpose, operability, condition, security, quiet
            enjoyment, value, and accuracy of data.
          </p>
          <br />
          <p>
            <strong>Release; Limitations On Liability</strong>
          </p>
          <br />
          <p>
            To the extent permitted by law, the Event Organiser hereby waives and releases
            ShowsHappening, and agrees that it will not pursue ShowsHappening or any of its owners,
            officers, employees, affiliates, agents, or licensors for any claims or liability
            arising from or related to any consequential, incidental, indirect, special, actual,
            punitive, and/or damages. These damages include, but are not limited to, loss of
            profits, revenues, goodwill, use of data, service interruption, computer damage or
            system failure, cost of substitute products or services, bodily injury, illness, death,
            or intangible losses, retention of any user communications or personalization settings,
            deletion or failure to store data, internet or other communication outages or delays,
            unauthorized access (hacking), or the occurrence of any natural disaster, pandemic, or
            other unforeseen contingency. These damages arise out of or relate in any way to the
            Event Organiser&apos;s use of ShowsHappening&apos;s services or their Event, regardless
            of whether such damages are under theory of breach of contract, tort, negligence,
            contract, warranty, statute, strict liability, or otherwise, even if ShowsHappening or
            its representatives have been advised of the possibility of such damages.
          </p>
          <br />
          <p>
            Notwithstanding anything to the contrary contained herein, ShowsHappening&apos;s
            cumulative liability to the Event Organiser arising from these standard terms, the use
            of or inability to use ShowsHappening services or hold the Event, will always be limited
            to €100. To the extent that ShowsHappening may not, as a matter of applicable law,
            disclaim any implied warranty or limit its liabilities, the scope and duration of such
            warranty and the extent of ShowsHappening&apos;s liability will be the minimum permitted
            under such applicable law.
          </p>
          <br />
          <p>
            <strong>Indemnification and Hold Harmless</strong>
          </p>
          <br />
          <p>
            The Event Organiser agrees to defend, indemnify, and hold harmless ShowsHappening, and
            any officers, managers, employees, agents, affiliates of ShowsHappening (collectively
            referred to as the (“ShowsHappening Indemnified Parties”), against any and all claims,
            losses, costs, penalties, fines, forfeitures, fees (including reasonable legal fees,
            accounting fees, costs, and expenses incurred in connection with any enforcement,
            including any action, claim, or suit brought, including on appeal, by a ShowsHappening
            Indemnified Party of any right to indemnification or other obligation), related costs,
            judgments, damages (whether direct, indirect, incidental, consequential, or otherwise),
            and any other costs, fees (including legal and accounting fees), and expenses
            (collectively referred to as “Losses”) that a ShowsHappening Indemnified Party may incur
            in connection with any claims, proceedings, or investigations asserted or threatened by
            any party against the ShowsHappening Indemnified Parties, relating to:
          </p>
          <ul style={{ marginTop: 16, marginBottom: 16, paddingLeft: 16, listStyle: 'none' }}>
            <li>(a) any act or omission by the ShowsHappening Indemnified Party;</li>
            <li>
              (b) the performance (or lack thereof) of ticketing and registration services and/or
              in-event management services by the ShowsHappening Indemnified Party;
            </li>
            <li>(c) an Event or a series of Events;</li>
            <li>
              (d) the provision of any goods or additional services to the Event Organiser by
              ShowsHappening;
            </li>
            <li>
              (e) any breach by the Event Organiser (or by any of the Event Organiser’s employees,
              agents, affiliates, partners, officers, or assigns) of any applicable law, rule, or
              regulation or the rights of any third party;
            </li>
            <li>
              (f) the collection and remission of taxes by ShowsHappening or the failure to collect
              and remit taxes; provided that such Losses are not solely the result of the gross
              negligence or wilful misconduct of the ShowsHappening Indemnified Party, as finally
              determined by a court of competent jurisdiction without the possibility of appeal or
              as otherwise agreed to by the ShowsHappening Indemnified Party and the Event
              Organiser.
            </li>
          </ul>
          <p>
            ShowsHappening Indemnified Party may notify the Event Organiser of any such Losses using
            the last contact information provided by the Event Organiser. A failure or delay in
            providing such notice will not limit the Event Organiser&apos;s obligations to defend,
            indemnify, and hold harmless a ShowsHappening Indemnified Party from the Losses. In
            certain circumstances, ShowsHappening may choose to defend itself, in which case, the
            Event Organiser agrees to cooperate with ShowsHappening in any way requested and
            acknowledges that such defence does not waive the Event Organiser&apos;s obligation to
            indemnify or reimburse ShowsHappening for defence costs or other Losses.
          </p>
          <br />
          <p>
            <strong>Dispute Resolution</strong>
          </p>
          <br />
          <p>
            The Standard Terms will be governed by and construed in all respects in accordance with
            the laws of Malta. Any dispute concerning the interpretation or enforcement of the
            Standard Terms shall be subject to the exclusive jurisdiction of the Maltese Courts.
          </p>
          <br />
          <p>
            <strong>Waiver: Severability</strong>
          </p>
          <br />
          <p>
            Nullity of one of the provisions or paragraphs thereof in these Standard Terms shall not
            affect the applicability of the other provisions or other paragraphs. In such a
            situation, the null and void provisions or paragraphs thereof shall be replaced by new
            provisions or paragraphs, the contents, scope, and objective of which correspond as far
            as possible with the old, null and void, provisions.
          </p>
          <br />
          <p>
            <strong>Modifications</strong>
          </p>
          <br />
          <p>
            ShowsHappening reserves the right to revise these Standard Terms at our sole discretion
            and as deemed necessary to maintain compliance with relevant applicable law. Revisions
            to these Standard Terms may occur at any time, without prior notice, and will become
            effective immediately upon posting. The updated Standard Terms may take effect prior to
            posting or be retroactively applied if we determine such retroactive application is
            necessary to adhere to relevant applicable law. Any additional modification or exception
            to these Standard Terms is not valid and effective unless it is in writing, specifically
            references these Standard Terms, and is duly signed by ShowsHappening.
          </p>
        </Typography>
      </div>
      {buttonSettings.publish && (
        <Checkbox
          onChange={handleAcceptTerms}
          checked={termsChecked}
          style={{ marginBottom: 16 }}
          disabled={accepting || activeEvent?.basicInfo?.status === 'acceptedTerms'}
          name="agreeTerms">
          I have read and agree to the Terms and Conditions
        </Checkbox>
      )}
      {buttonSettings.publish && (
        <Button
          disabled={activeEvent?.basicInfo?.status !== 'acceptedTerms'}
          style={{ width: '100%' }}
          onClick={() => setOpenedPublishEventModal(true)}
          size="large">
          {t('common_publish_event')}
        </Button>
      )}
      {buttonSettings.pause && (
        <Button
          style={{ width: '100%' }}
          onClick={handlePauseEvent}
          size="large"
          loading={isPausing}>
          <Icon name="pause" />
          {t('event_pause_event')}
        </Button>
      )}
      {buttonSettings.replay && (
        <Button
          style={{ width: '100%' }}
          onClick={handlePublishEvent}
          size="large"
          loading={isPublishLoading}>
          <Icon name="play" />
          Restart
        </Button>
      )}

      <PublishEventModal
        open={openedPublishEventModal}
        handleClose={() => setOpenedPublishEventModal(false)}
      />
    </div>
  );
};

export default EventCreateFormPublish;
