import { FC } from 'react';

import s from './EventsEmptyState.module.scss';

interface EventsLoadingStateProps {
  size?: number; // Accept a numeric size prop
}

const EventsLoadingState: FC<EventsLoadingStateProps> = ({ size = 50 }) => {
  return (
    <div className={s.empty}>
      <img
        src="/assets/images/logo-loop.svg"
        className={s.img}
        alt="loading"
        style={{ width: size, height: size }} // Apply the numeric size to both width and height
      />
    </div>
  );
};

export default EventsLoadingState;
